// Dropdown

.dropdown-menu {
  box-shadow: 0 0.46875rem 2.1875rem rgba(darken($primary, 50%), 0.03), 0 0.9375rem 1.40625rem rgba(darken($primary, 50%), 0.03),
    0 0.25rem 0.53125rem rgba(darken($primary, 50%), 0.05), 0 0.125rem 0.1875rem rgba(darken($primary, 50%), 0.03);
  font-size: $font-size-base;

  margin: $dropdown-spacer;

  &.dropdown-menu-right {
    right: 0 !important;
  }

  .dropdown-header {
    text-transform: uppercase;
    font-size: $font-size-base / 1.2;
    color: $primary;
    font-weight: bold;
  }

  .dropdown-item {
    font-size: $font-size-base;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    z-index: 6;
    position: relative;

    .dropdown-icon {
      font-size: 1rem;
      margin-right: ($dropdown-padding-y / 2);
      width: 30px;
      text-align: center;
      opacity: 0.3;
      margin-left: -10px;
    }

    &:hover {
      .dropdown-icon {
        opacity: 0.7;
      }
    }
  }

  &.dropdown-menu-shadow {
    box-shadow: 0 0.66875rem 2.3875rem rgba(darken($primary, 50%), 0.03), 0 1.1375rem 1.60625rem rgba(darken($primary, 50%), 0.03),
      0 0.45rem 0.73125rem rgba(darken($primary, 50%), 0.05), 0 0.325rem 0.3875rem rgba(darken($primary, 50%), 0.03);
  }
}

.dropdown-menu-rounded {
  @include border-radius(10px);
  padding: $dropdown-padding-y;

  .dropdown-item {
    @include border-radius(30px);
  }

  .dropdown-divider {
    margin-left: -$dropdown-padding-y;
    margin-right: -$dropdown-padding-y;
  }

  .dropdown-menu-header {
    margin-left: -$dropdown-padding-y;
    margin-right: -$dropdown-padding-y;
    @include border-top-radius(10px);
  }

  .menu-header-image,
  .dropdown-menu-header-inner {
    @include border-top-radius(10px);
  }
}

.dropdown-menu-hover-link {
  .dropdown-item {
    &:hover {
      background: none;
      color: $primary;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.dropdown-menu-hover-primary {
  .dropdown-item {
    &:hover {
      background: $primary;
      color: $white;
    }
  }
}

.dropdown-menu {
  &.dropdown-menu-lg {
    min-width: 22rem;
    max-height: 75px;
    .logout-span{
      cursor: pointer;
    }
  }

  &.dropdown-menu-xl {
    min-width: 25rem;
  }
}

// Dropdown header
.dropdown-menu {
  .dropdown-menu-header,
  .menu-header-image,
  .dropdown-menu-header-inner {
    @include border-top-radius($dropdown-border-radius);
  }
}

.dropdown-menu-header {
  color: $white;
  margin-top: -$dropdown-padding-y;
  margin-bottom: $dropdown-padding-y;
  position: relative;
  z-index: 6;

  .dropdown-menu-header-inner {
    margin: -1px -1px 0;
    padding: ($spacer * 1.5) ($spacer / 2);
    position: relative;
  }

  .menu-header-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 8;
    opacity: .25;
    filter: grayscale(80%);
    background-size: cover;
  }

  .menu-header-content {
    text-align: center;
    position: relative;
    z-index: 10;

    &.text-left {
      padding-left: ($spacer / 2);
    }

    &.btn-pane-right {
      padding-left: ($spacer / 2);
      padding-right: ($spacer / 2);
      display: flex;
      align-content: center;
      align-items: center;
      text-align: left;

      .menu-header-btn-pane {
        margin: 0 0 0 auto;
      }
    }

    .menu-header-btn-pane {
      margin-top: 10px;
      margin-bottom: 3px;
    }
  }

  & + .grid-menu {
    margin-top: -$dropdown-padding-y;
  }
}

.menu-header-title {
  font-weight: 500;
  font-size: $h5-font-size;
  margin: 0;
}

.menu-header-subtitle {
  font-size: $font-family-base;
  margin: 5px 0 0;
  opacity: .8;
}

.dropdown-menu {
  .grid-menu {
    margin-bottom: -$dropdown-padding-y;
    padding: 1px;

    [class*="col-"] {
      padding: $dropdown-padding-y;
    }
  }

  .grid-menu-xl {
    margin-bottom: -($dropdown-padding-y / 1.35);

    [class*="col-"] {
      padding: 0;
    }
  }
}

// Dropdown toggle

.dropdown-toggle {
  &::after {
    position: relative;
    top: 2px;
    opacity: .8;
    margin-left: 5px;
  }
}

.dropdown-toggle-split {
  &::after {
    margin-left: 0;
  }
}

.dropright {
  .dropdown-toggle {
    &::after {
      top: 0;
    }
  }
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, .1) solid 2px;
}

// Dropdown Indicators
.dropdown-menu {
  &::before,
  &::after {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 5px;
    top: -5px;
    transform: rotate(45deg);
    border-radius: 4px;
    content: '';
    display: block;
    z-index: 5;
  }

  &::before {
    background: $dropdown-bg;
    z-index: 5;
  }

  &::after {
    top: -6px;
    background: $dropdown-border-color;
    z-index: 4;
  }

  &[data-placement="top-start"] {
    &::before,
    &::after {
      top: auto;
      bottom: -5px;
    }

    &::after {
      bottom: -6px;
    }
  }

  &[data-placement="left-start"] {
    &::before,
    &::after {
      left: auto;
      right: -5px;
      top: 5px;
    }

    &::after {
      top: 5px;
      right: -6px;
    }
  }

  &[data-placement="right-start"] {
    &::before,
    &::after {
      right: auto;
      left: -5px;
      top: 5px;
    }

    &::after {
      top: 5px;
      left: -6px;
    }
  }

  &.dropdown-menu-right {
    &::before,
    &::after {
      left: auto;
      right: 6px;
    }

    &::after {
      right: 6px;
    }
  }

  &.rm-pointers {
    &::before,
    &::after {
      display: none;
    }
  }
}

// Dropdown Mega Menu
.dropdown-mega-menu {
  width: 56rem;
  padding: $spacer;

  .nav-item.nav-item-header {
    text-transform: none;
    font-size: $h6-font-size;
    padding-top: 0;
    font-weight: normal;
  }

  .grid-menu {
    margin-bottom: 0;
  }
}

.dropdown-mega-menu-sm {
  width: 40rem;
}

// Dropdown Inline
body .dropdown-menu.dropdown-menu-inline {
  border: 0;
  position: static !important;
  box-shadow: 0 0 0 transparent;
  background: transparent;
  @include border-radius(0);
  display: inline-block;
  float: none;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  transform: translateY(0) !important;

  &::before,
  &::after {
    display: none;
  }
}

.dropdown.filter-dropdown {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  cursor: pointer;

  &.primary {
    color: $primary;

    &:hover {
      color: $blue;
    }

    &:disabled {
      color: $gray-600;
    }
  }

  &.alerts-dropdown,&.devices-dropdown {
    .dropdown-menu {
      @include media-breakpoint-down(md){
        max-width: 40%;
      }
      @include media-breakpoint-only(xs){
        max-width: 60%;
      }
      @include media-breakpoint-down(xs){
        right: -100% !important;
        top: 25px !important;
      }
      &::before,
      &::after {
        left: auto;
        right: -7px;
        top: 3px;
        border-radius: 0;
      }
      &::after {
        left: auto;
        right: -9px;
        background: rgba(0, 0, 0, 0.05);
      }
      &:before {
        border-color: rgba(235, 235, 235, 0);
        border-left-color: #F2F4F6;
        border-width:9px;
      }
    }

    #filterDevicesTable,
    #versionMenu{
      @include media-breakpoint-down(sm){
        min-width: 300px;
      }

      @include media-breakpoint-down(xs){
        max-width: 300px;
        min-width: auto;
      }

      .item {
        padding: 1.5rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        &.hidden {
          display: none;
        }

        .custom-checkbox {
          text-transform: capitalize;
        }

        button {
          height: 25px;
          display: flex;
          text-align: left;
          margin: 0;
          padding: 0;
          .arrow-filter-item {
            transition: 0.3s ease;
            box-sizing: content-box;
            transform-origin: center center;
          }
          .filter-btn-wrap {
            margin-left: 15px;
            font-size: 14px;

          }

          &[aria-expanded="true"]{
            .arrow-filter-item {
              transform: rotate(180deg);
            }
          }
        }
        .filter-items {
          margin-left: 25px;
        }
      }
    }
  }
  &.devices-dropdown{
    @include media-breakpoint-down(md){
      .dropdown-menu{
        max-width: 40%;
      }
    }
    @include media-breakpoint-only(xs){
      .dropdown-menu{
        max-width: 70%;
      }
    }
    #filterDevicesTable{
      min-height: 148px;
      .custom-checkbox{
        margin-bottom: 10px;
      }
    }
  }

  .dropdown-menu {
    background-color: $dropdown-bg;
    border: 1px solid #ebebeb;
    border-radius: 1px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 1em;
    padding: 0;
    .item {
      padding: 0 25px;
      border-bottom: 1px solid #ebebeb;
      font-size: 1em;
      font-weight: lighter;
      &.hidden {
        display: none;
      }

      button {
        font-size: 1em;
        font-weight: bold;
        text-decoration: none;
      }

      .collapse {
        //margin: 15px 20px;
        transition: all 0.1s linear;
      }
    }
  }

  &.filter-drop-left {
    .dropdown-menu {
      text-transform: none;
      &::before,
      &::after {
        left: auto;
        right: -7px;
        top: 3px;
        border-radius: 0;
      }

      &::after {
        left: auto;
        right: -9px;
        top: 3px;
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .dropdown-toggle {
    &:before {
      display: none;
    }
  }

 // checkbox
  .custom-checkbox {
    .custom-control-label {
      vertical-align: middle;

      &::before {
        top:0
      }
    }
  }
}

// Action Drop Down
.actions-dropdown {
  &.dropleft .dropdown-toggle::before {
    display: none;
  }

  .dropdown-menu {
    position: relative;
    min-width: 9rem;
    width: max-content;
    background: #f2f4f6;
    padding: 0.5rem 0;
    font-size: 1em;
    border: 1px solid #ebebeb;
    border-radius: 1px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    @include media-breakpoint-up(lg) {
      top: -2rem !important;
    }

    &:after,
    &:before {
      left: 100%;
      top: calc(15% + 2rem);
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      display: inline-block;
      transform: rotate(0);
      background: transparent;
    }

    &:after {
      border-color: rgba(242, 244, 246, 0);
      border-left-color: #c1c1c1;
      border-width: 9px;
      margin-top: -9px;
    }
    &:before {
      border-color: rgba(235, 235, 235, 0);
      border-left-color: #f2f4f6;
      border-width: 9px;
      margin-top: -9px;
    }

    .dropdown-item {
      color: $primary;
      font-weight: 600;
      svg {
        margin-right: 1rem;
      }

      &:hover,
      &:focus {
        color: $blue;
      }
    }

    .dropdown-divider {
      border-top: 1px solid #ebebeb;
      margin: 0.25rem 0;
    }
  }
}
