// Theme White

// Variables
$app-container-bg: #f1f4f6;
$app-sidebar-bg: #ffffff;
$app-header-bg: #fafbfc;
$app-header-logo-bg: rgba(255, 255, 255, .9);

// Content

.app-theme-white {

  &.app-container {
    background: $app-container-bg;
  }

  .app-sidebar {
    background: $app-sidebar-bg;
  }

  .app-header {
    background: $app-header-bg;
    background: linear-gradient(318deg, rgba(23,53,56,1) 0%, rgba(22,25,99,1) 100%);
  }

  .app-footer .app-footer__inner{
    background: $app-header-bg;
  }

  &.fixed-header {
    .app-header__logo {
      background: rgba($app-header-bg, .1);
    }
  }

  .Burger {
    color: $app-sidebar-bg;
    padding: 0;
      &.BurgerSlider {
        .BurgerInner {
          width: 19px;
          background-color: $app-sidebar-bg;
          &:before,
          &:after {
            background-color: $app-sidebar-bg;
          }
        }
      }
  }
}