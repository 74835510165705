.label-demo {
  display: inline-block;
  float: left;
  padding: 0.5rem;
  background: #BADEF8;
  color: $primary;
  border-radius: 6px;
  margin: .35rem;
  font-size: .75rem;

  b {
    font-size: .7rem;
  }
}