
// Popover
.popover {
  background: #D8D9E3;
  border: 1px solid rgba(0, 0, 0, 0.025);
  font-size: 0.75rem;
  line-height: 15px;
  padding: 0.5rem 0.75rem;

  .popover-header {
    font-size: 0.75rem;
    padding: 0 0 0.25rem 0;
    background: #D8D9E3;
    border: none;
    font-weight: bold;
  }

  .popover-body {
    padding: 0;
    font-weight: lighter;
  }

}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom{
  > .arrow {
    &:after {
      border-bottom-color: #D8D9E3;
    }
  }
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  .popover-header {
    &::before {
      border-bottom: 1px solid #D8D9E3;
    }
  }
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #D8D9E3;
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color:#D8D9E3;
}


// ToolTip
.tooltip  {
  .tooltip-inner {
    max-width: 200px;
    padding: .65rem;
    color: $primary;
    text-align: center;
    background-color: #D8D9E3;
    border-radius: .55rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: .90rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
  }

  .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;
    border-radius: .55rem;
    &:before  {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-width: .45rem 0 .45rem .45rem;
  border-left-color: #D8D9E3;
  border-radius: .75rem;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-width: 0 .45rem .45rem;
  border-bottom-color: #D8D9E3;
  border-radius: .75rem;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #D8D9E3;
  border-radius: .75rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-width: .45rem .45rem .45rem 0;
  border-right-color: #D8D9E3;
  border-radius: .75rem;
}