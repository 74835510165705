.reports {

  &.charts {
    position: relative;
  }

  .no-chart {
    text-align: center;
    font-size: 1.45rem;
  }

  &.header {
    .page-title-actions {
      justify-items: center;
      align-items: center;
      align-self: flex-end;
      position: relative;
      z-index: 2;

      label {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: right;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;

        @include media-breakpoint-down(md) {
          text-align: left;
          flex: 0 0 31.66667%;
          max-width: 31.66667%;
        }
      }
    }

    .react-select-container {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      @include media-breakpoint-down(md) {
        flex: 0 0 68.33333%;
        max-width: 68.33333%;
        padding: 0;
      }

      .react-select__control {
        background-color: transparent;
        min-height: 31px;
        height: 31px;
        font-size: 13px;
        line-height: 16px;
        font-weight: bold;
      }

      .react-select__menu {
        z-index: 11;
      }

      .react-select__value-container {
        .react-select__single-value {
          font-size: 13px;
        }
      }
    }
  }

  &.dates {
    padding: 1rem 1.5rem 0.5rem;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    z-index: 1;

    .form-group {
      margin: 0;
      .input-group-prepend {
        .input-group-text {
          background-color: #efeff4;
          color: $primary;
        }
      }

      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          .form-control {
            height: calc(1.5em + 0.75rem);
            font-size: 13px;
            font-weight: 700;
            color: $gray-500;
            border-color: #d8d9e3;
          }
        }
      }
      .input-group{
        z-index: 1;
      }
    }

    .go {
      display: flex;
      align-items: flex-end;

      @include media-breakpoint-down(sm) {
        display: block;
        padding-top: 1rem;

        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }

  // overview
  .overview {
    .card {
      min-height: 100%;
    }

    // Chart
    .radial-bar-infection {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @include media-breakpoint-up(md) {
        width: 90%;
        margin: auto;
      }

      .chart {
        margin: 0;
        padding: 0;
        max-width: 50%;

        .apexcharts-svg {
          margin: 0;
          padding: 0;
          .apexcharts-radialbar {
            margin: 0;
            padding: 0;
            @include media-breakpoint-up(lg) {
              transform: translateY(-20px) translateX(-20px) scale(1.3);
            }

            @include media-breakpoint-down(lg) {
              transform: translateY(-20px) translateX(-15px) scale(1.3);
            }
          }
        }
      }
      .overall {
        //width: 70%;
        .num {
          font-size: 50px;
          line-height: 55px;
          font-weight: 900;
          color: #545aeb;
        }
        .text {
          font-size: 20px;
          font-weight: lighter;
        }
      }
    }
  }

  // agent
  .agent {
    .card {
      height: 100%;

      @include media-breakpoint-down(md) {
        .card-title {
          font-size: 16px;
        }
      }
    }

    .chart {
      height: 100%;

      .apexcharts-canvas{
          width: 100%;
        .apexcharts-legend{
          width: 70%;
        }

        .apexcharts-inner {
          width: 50%;
          //transform: scale(0.5);
        }
      }

      .no-chart {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        margin-top: -12px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
          padding: 0 15px 15px;
        }
      }
    }
  }

  .btn-actions-pane-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .infected{
    .apexcharts-legend{
      .apexcharts-legend-series{
        .apexcharts-legend-text{
          font-size: 14px !important;
        }
      }
    }
    &.flatten{
      .apexcharts-legend-marker{
        top: 3px !important;
      }
      .card-title{
        font-size: 18px;
      }
      .apexcharts-legend-series{
        margin-bottom: 10px !important;
      }
      .apexcharts-text{
        span{
          font-family: 'muli', sans-serif;
        }
      }
    }
  }
}

.reports {
  //Malware
  .report-malware {
    .card {
      min-height: 100%;

      .card-title {
        font-size: 18px;
        padding: 1.25rem;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        padding: 0 1.25rem 1.25rem;
      }

    }
  }

  .order-lg-3 {
    z-index: 1;
  }

  .order-lg-2,
  .order-lg-1 {
    z-index: 0;
  }

  .chart-donut-container {
    @include media-breakpoint-down(lg) {
      padding-bottom: 0.75rem;
    }

    .donut {
      max-width: 400px;
      margin: auto;
      width: 99%;

      .apexcharts-legend {
        min-width: 115px;
        //justify-content: space-evenly;
        @include media-breakpoint-down(lg) {
          top: 20px !important;
        }

        @include media-breakpoint-down(md) {
          top: 20px !important;
        }

        @include media-breakpoint-down(sm) {
          top: 25px !important;
        }

        @include media-breakpoint-down(xs) {
          top: 0 !important;
        }

        @include media-breakpoint-up(xxl) {
          top: 25px !important;
        }

        @include media-breakpoint-up(xl) {
          top: 0 !important;
        }

        .apexcharts-legend-series {
          &:not(&:first-of-type) {
            @include media-breakpoint-down(xxl) {
              margin-bottom: 5px !important;
            }

            @include media-breakpoint-down(lg) {
              margin-bottom: 10px !important;
            }

            @include media-breakpoint-down(md) {
              margin-bottom: 26px !important;
            }

            @include media-breakpoint-down(sm) {
              margin-bottom: 5px !important;
            }

            @include media-breakpoint-down(xs) {
              margin-bottom: 2px !important;
              transform: scale(0.9);
            }

            @include media-breakpoint-up(xxl) {
              margin-bottom: 25px !important;
            }

            @include media-breakpoint-up(xl) {
              margin-bottom: 5px !important;
            }
          }
        }
      }

      .no-chart {
        text-align: center;
        font-size: 1.45rem;
      }
    }
  }
  &.charts{
    z-index: 0;
  }
}

.reports-table {
  overflow: hidden;

  .text-color-Clean {
    color: $success;
  }
  .text-color-Infected {
    color: $danger;
  }
  .text-color-UpdateRequired {
    color: $warning;
  }
  .text-color-LowSecurityScore {
    color: $secondary;
  }

  .card-header {

    @include media-breakpoint-down(md) {
      padding: 0.75rem;
      .search-input .close {
        display: none;
      }
    }

    .reports-actions {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      position: relative;
      height: 63%;

      @include media-breakpoint-down(md) {
        padding-top: 0.75rem;
      }

      .separator {
        position: relative;
        width: 1px;
        height: 80%;
        margin: 0 15px;
        background: $primary;
        display: inline-block;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }



}
@media screen and (max-width: 768px){
  .reports{
    .infected{
      &.flatten{
        .card-title{
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .btn-actions-pane-right {
    margin-top: 10px;
  }
  .reportsEndDateLabel {
    margin-top: 10px;
  }
}
@media screen and (max-width: 576px){
  .reports{
    .agent{
      .chart{
        .apexcharts-canvas .apexcharts-legend{
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 360px){
  .reports{
    .app-page-title{
      padding: 40px 3px 0px 0px;
    }

  }
  &.dates{
    .btn-actions-pane-right{
      position: absolute;
      right: 1rem;
      top: 0;

    }
  }
  .malware-definitions-wrap{
    margin-top: 10px;
  }
}