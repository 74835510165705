.has-more-devices,
.has-more-labels{
  margin-left: 2.5em;
  font-size: .75em;
  line-height: 1.10em;
  font-weight: 800;
  padding-top: .75em;
  text-transform: uppercase;
  color: $primary;

  &:hover {
    color: $blue
  }
}
.btn-primary{
  &:disabled{
    background-color: $light-gray;
    border-color: $light-gray;
  }
  &:hover{
    background-color: $blue;
    border-color: $blue;
  }
}

.has-more-labels {
  float: left;
  margin-top: .55em;
  margin-left: .65em;
}

.policies-toggle-applies-devices,
.policies-toggle-applies-labels{
  display: table-cell;
  max-width: 95%;
  width: max-content;
  &.disabled {
    display: none;
    transition: all 1s ease-in-out;
  }
}
.policies-table-top{
 .table-search-wrapper{
   padding-top: 13px;
 }
}
.protection-levels{
  margin-top: 14px;
  .extra-bold{
    font-weight: 900;
  }
  .protection-level{
    margin-bottom: 10px;
    font-size: 14px;
  }
  @include media-breakpoint-down(sm){
    .protection-level{
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}
.policies-header{
  .page-title-subheading{
    font-size: 14px;
    color: $dark-blue;
    font-weight: 700;
    opacity: 1;
    text-transform: none;
    @include media-breakpoint-down(sm){
      display: none;
    }
  }
  .app-page-title{
    padding: 40px 45px 0 30px;
    margin: -30px -30px 10px;

    @include media-breakpoint-down(sm){
      padding: 10px 0 0 0;
      margin: 0;
    }
  }
}
.policies-table {
  width: 100%;
  height: auto;

  .max-td-width {
    width: 450px;
  }
}

// Polices Modal
.rodal {
  &.policy-detail-modal {
    position: absolute;
    height: 100%;
    width: 100%;

    .rodal-dialog {
      top: 0;
      height: 100% !important;
      width: 100% !important;
      box-shadow: 0 .1rem .1rem rgba(52, 58, 64, 0.3);

      .modal-body {
        height: calc(80% - 1rem)
      }

      .scroll-area {
        height: 100%;
      }

      .modal-row,
      .modal-col {
        height: 100%;
      }

      .modal-col {
        &:last-child {
          border-left: 1px solid #D8D9E3;
        }
        @media (max-width: 576px) {
          height: calc(50% - 1rem);
          &:last-child {
            padding-top: 1rem;
            border-top: 1px solid #D8D9E3;
            border-left: none;
          }
        }
      }

      // Card
      .card {
        box-shadow: none;
        border: none;

        h6 {
          font-weight: 800;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $primary;
          text-transform: uppercase;
        }

        .card-header {
          border: none;
          color: $primary;
          font-weight: 500;

          > .nav {
            .nav-link {
              color: $primary;
              height: 70%;

              &:before {
                height: 1px;
                width: 50%;
                margin: auto;
                left: 0;
                right: 0;
                bottom: .9rem;
                background-color: $blue;
              }

              &.active {
                color: $blue;
                background: #F2F3FF;
                border-radius: .25rem;
              }
            }
          }
        }
      }
    }
  }
}

// Beta Policy
.policy-container {
  overflow-x: visible;

  .policy-table {
    min-height: calc(60vh - 200px);
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      min-height: 200px;
    }

    .card-header{
      padding: 0.75rem 0rem;
    }
    tbody td,
    thead td{
      &:nth-child(2) {
        width: 1%;
        white-space: nowrap;
        padding: 0.55rem 1rem;
        @include media-breakpoint-down(md){
          padding: 0.55rem 1rem 0 0;
        }
      }

      &:nth-child(3) {
        padding: 0.55rem 1.5rem;
        @include media-breakpoint-down(md){
          padding: 0.55rem 1rem 0 1rem;
        }
      }
    }

    .policy-select-container {
      max-width: 195px;
    }

    .policy-detail-name {
      display: flex;
      align-items: center;
      padding: 0 7rem 0 0.5rem;
      max-width: max-content;

      @include media-breakpoint-down(md) {
        padding: 0 2rem 0 0.5rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
      }
    }
    @include media-breakpoint-only(xs){
      thead{
        th{
          &:nth-of-type(3){
            min-width: 200px;
          }
        }
      }
    }
  }

  &.policy-table-footer {
    border: none;
    justify-content: flex-end;
    @include media-breakpoint-up(md) {
      padding: 1rem 2.5rem 2rem 2rem;
    }

    .btn {
      @include media-breakpoint-up(md) {
        min-width: 165px;
        font-size: 16px;
      }

    }
    @include media-breakpoint-only(xs){
     justify-content: center;
    }
  }
  @include media-breakpoint-only(xs){
    overflow-x:scroll;
    overflow-y: visible;
  }
}