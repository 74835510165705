// Header Base
.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all .2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x + 1;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
      visibility: hidden;
      opacity: 0;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-info {
    color: $gray-200;
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }

  .user-box {
    .custom-user-icon-name {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 30px;
      height: 30px;
      background-color: $gray-100;
      border-radius: 50%;
      float: right;
      margin: 0 10px;
    }

    .dropdown-menu {
      min-width: 100px;
    }

    .logout-span {
      padding: 0 10px 0 15px;
      font-size: 13px;
      text-align: left;
    }
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x / 4 0 $layout-spacer-x / 2 ;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width .2s;

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background: url('~assets/utils/images/logoNav.svg') no-repeat;
    background-size: cover;
    margin-bottom: 5px;
    @include media-breakpoint-down(md){
    //transform: scale(1.1);
      height: $logo-height-sm;
      width: $logo-width-sm;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm){
    //transform: scale(1.1);
      height: $logo-height;
      width: $logo-width;

    }
  }
}

.app-header__menu,
.app-header__mobile-menu {
  z-index: 100;
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";