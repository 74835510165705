// Navigation Menu Vertical
.vertical-nav-menu {
  position: relative;

  &::after {
    content: ' ';
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .metismenu-container,
  .metismenu-item {
    margin: 0;
    padding: 0;
    position: relative;
  }

  // Level One
  .metismenu-container {
    list-style: none;
    &:before {
      opacity: 0;
      transition: opacity 300ms;
    }

    // Item
    .metismenu-item {
      padding: 10px 0;
    }

    // Icon
    i.metismenu-state-icon,
    i.metismenu-icon {
      text-align: center;
      width: 34px;
      height: 34px;
      line-height: 34px;
      position: absolute;
      left: 5px;
      top: 50%;
      margin-top: -17px;
      font-size: 1.5rem;
      color: $primary;
      transition: color 300ms;
    }

    // Icon
    i.metismenu-state-icon {
      transition: transform 300ms;
      left: auto;
      right: 0;

      &.rotate-minus-90 {
        transform: rotate(-180deg);
      }
    }

    // Links
    .metismenu-link {
      transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms;
      display: block;
      line-height: 2.7rem;
      height: 2.5rem;
      padding: 0 $layout-spacer-x 0 75px;
      position: relative;
      @include border-radius($border-radius);
      color: $gray-800;
      white-space: nowrap;

      // Links Icon
      i.metismenu-icon {
        left: 25px;

        //&.lnr-home {
        //  &::before {
        //    content: "";
        //    width: 24px;
        //    height: 22px;
        //    display: inline-block;
        //    mask: url("../assets/utils/icons/alerts.svg");
        //    -webkit-mask: url("../assets/utils/icons/alerts.svg");
        //    -webkit-mask-size: cover;
        //    mask-size: cover;
        //    background-color: $gray-800;
        //  }
        //}
      }
      // Links Hover
      &:hover {
        text-decoration: none;
        color: $blue;
        // Links Hover Icon
        i.metismenu-icon {
          opacity: 1;
          color: $blue;
          &::before {
            //background-color: $blue;
          }
        }
      }

      // Links Active
      &.active {
        color: $primary;
        border-radius: 0;
        border-left: 7px solid $primary;
        padding: 0 $layout-spacer-x 0 65px;
        // Links Active Icon
        i.metismenu-icon {
          opacity: 1;
          color: $blue;
          left: 18px;
          &::before {
            //background-color: $blue;
          }

        }
      }

      &.has-active-child {
        color: $primary;
        border-radius: 0;
        border-left: 7px solid $primary;
        padding: 0 $layout-spacer-x 0 65px;
        // Links Active Icon
        i.metismenu-icon {
          opacity: 1;
          color: $blue;
          left: 18px;
          &::before {
            //background-color: $primary;
          }
        }
      }

      &.has-active-child,
      &.active {
        font-weight: bold;
      }
    }

    // Level Two ---
    .metismenu-container {
      .metismenu-item .metismenu-link {
        padding: 0 1.5rem 0 72px;
      }

      &.visible {
        padding: 0.1em 0 0 0;

        & > .metismenu-item > .metismenu-link {
          height: 2.2em;
          color: $primary;
          border-left: transparent 0 solid;
          padding: 0 1.5rem 0 72px;

          &:hover {
            color: $blue;
          }

          &.active {
            color: $blue;
            border-left: transparent 0 solid;
          }
        }
      }

      .metismenu-item > .metismenu-link {
        height: 0;
        overflow: hidden;
        border-left: transparent 0 solid;
        //transition: height 0.26s ease, overflow 0.6s ease;
        //padding: 0 1.5rem 0 66px;
        line-height: 2em;
      }

      .metismenu-item {
        padding: 0;
      }
    }
  }
}
