::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  border-radius: 7px;
}

::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  //outline: 1px solid slategrey;
  border-radius: 7px;
  background: #d8d9e3;
}

::-webkit-scrollbar-thumb:hover {
  background: #bfc0c6;
}

// Helpers imports
@import 'helpers/opacity';
@import 'helpers/grayscale';

// Resets
.br-tl {
  border-top-left-radius: $border-radius !important;
}

.br-tr {
  border-top-right-radius: $border-radius !important;
}

.br-bl {
  border-bottom-left-radius: $border-radius !important;
}

.br-br {
  border-bottom-right-radius: $border-radius !important;
}

.b-radius-0 {
  border-radius: 0 !important;
}

.rm-border {
  border-width: 0 !important;
}

.br-a {
  @include border-radius($border-radius);
}

// Center Elements
.margin-h-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-svg {
  margin: 0 auto;

  svg {
    margin: 0 auto;
  }
}

.apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-donut {
  display: flex;
  align-items: center;
  align-content: center;
}

.alert-dismissible {
  .close {
    top: 0;
    right: 5px;
    padding: 5px;
  }
}

// Gradient Icons
.icon-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

// Font sizes

.font-size-xlg {
  font-size: 1.3rem !important;
}

.font-size-md {
  font-size: 0.9rem !important;
}

.font-size-lg {
  font-size: 1.1rem !important;
}

// No Shadow
.no-shadow {
  box-shadow: 0 0 0 transparent !important;
}

// Layout
.vh-100 {
  height: 100vh !important;
}

.h-100 {
  min-height: 100vh !important;
}

.he-auto {
  height: auto !important;
}

.he-100 {
  height: 100%;
}

.h-sm {
  height: 150px;
}

.text-nowrap {
  white-space: nowrap;
}

// Font size
.fsize-1 {
  font-size: 0.95rem !important;
}

.fsize-2 {
  font-size: 1.3rem !important;
}

.fsize-3 {
  font-size: 1.6rem !important;
}

.fsize-4 {
  font-size: 2rem !important;
}

.z-index-6 {
  z-index: 6;
}

.line-height-1 {
  line-height: 1;
}

// Center element

.center-elem {
  display: flex;
  align-items: center;
  align-content: center;
}

.flex2 {
  flex: 2;
}

// Divider
.divider {
  margin-top: $spacer;
  margin-bottom: $spacer;
  height: 1px;
  overflow: hidden;
  background: $gray-200;
}

.list-group-item:hover {
  z-index: initial;
}

// No Results
.no-results {
  padding: ($spacer * 1.5);
  text-align: center;

  .results-title {
    color: $gray-700;
    font-size: $h5-font-size;
  }

  .results-subtitle {
    color: $gray-500;
    font-size: $font-size-lg;
  }
}

// Background Color Animation
.bg-animation {
  animation: bg-pan-left 8s both;
}

@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Width 100%
.w-100 {
  width: 100%;
}

.mb--2 {
  margin-bottom: -1.5rem;
}

// Margins
.mbg-3 {
  margin-bottom: $grid-gutter-width;
}

// Loader
.loader-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  text-align: center;
  line-height: 1.6rem;

  .loader-container-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 $layout-spacer-x;
  }

  small {
    margin: 10px 0 0;
    display: block;
    color: $gray-600;
    font-weight: normal;
  }

  h3 {
    text-align: center;
    display: block;
  }

  &.content {
    height: calc(100vh - 170px);
    width: 100%;
  }
}

.version-muted-text {
  display: flex;
  margin: -0.2rem 0 0 2.5rem;
  font-size: 11px;
  font-weight: lighter;
  color: $primary;
}

// Tables

.table-responsive {
  &.limited {
    overflow-y: auto;
    height: 400px;
    thead th {
      position: sticky;
      position: -webkit-sticky;
      top: -2px;
      background: white;
      border-bottom: 2px solid  #ebebeb;
      border-top: 2px solid  #ebebeb;
    }

    thead th {
      box-shadow: 0 2px #ebebeb, 0 -2px #ebebeb;
    }

    thead tr {
      box-shadow: 0 1px #ebebeb, 0 -1px #ebebeb, -1px 0 #ebebeb;
    }
  }
}

.b2b-table {
  color: $primary;

  td {
    border-right: 1px solid #ebebeb;

    &:last-child {
      border-right: 0;
    }
  }

  thead {
    th {
      border-right: 2px solid #ebebeb;
      border-bottom: 2px solid #ebebeb;
      border-top: 2px solid #ebebeb;
      text-transform: uppercase;
      padding: 0.8rem 0.6rem 0.4rem 0.6rem;

      .custom-control {
        margin-top: -1px;
      }

      &:first-of-type {
        width: 37px;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  &.no-checkbox {
    thead {
      th {
        &:first-of-type {
          width: auto;
        }
      }
    }
  }

  #text-filter-column-name,
  #multiselect-filter-column-labels,
  #multiselect-filter-column-malwareType,
  #multiselect-filter-column-actionPerformed,
  #multiselect-filter-column-detectionMethod,
  #multiselect-filter-column-productVersion,
  #multiselect-filter-column-stringStatus,
  #multiselect-filter-column-status,
  #multiselect-filter-column-definitionVersion,
  #multiselect-filter-column-type,
  #multiselect-filter-column-os,
  #text-filter-column-alert,
  #multiselect-filter-column-priority,
  #multiselect-filter-column-notificationType,
  #multiselect-filter-column-operatingSystem {
    display: none;
  }
}


// Loading Mask
// -- Loaders
.loader-block {
  opacity: 0;
  min-height: 100%;
  position: absolute;
  display: none;

  &.isLoading {
    opacity: 1;
    z-index: 9;
    background: rgba(246,249,255, .9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
  }

  &.fullLoading {
    opacity: 1;
    position: fixed;
    z-index: 101;
    background: rgba(22,28,93, .75);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }
}