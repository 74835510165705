// Modals

.modal-header,
.modal-footer {
  background: $gray-100;
}

.modal-footer {
  @include border-bottom-radius($border-radius);
}

.modal-dialog {
  box-shadow: $box-shadow-lg-default;
  @include border-radius($border-radius);
}

// Rodal
.rodal {
  text-align: left;
  // Rodal close button
  .rodal-close {
    position: absolute;
    cursor: pointer;
    top: 18px;
    right: 18px;
    width: 14px;
    height: 14px;
  }

  // Header
  .modal-header {
    background: #ffffff;
    margin: 0 1.75rem;
    border-bottom: 1px solid $primary;
  }

  // Title
  .modal-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    text-transform: none;
    color: $primary;
  }

  // Body
  .modal-body {
    margin: 0 2rem;
    font-size: 14px;
    text-transform: none;
  }

  // Footer
  .modal-footer {
    background: #ffffff;
    margin: .5rem 1rem;
    border: none;

    &.border-top {
      border-top: 1px solid $gray-300;
    }
  }
}