.onboarding {
  .wp-100 {
    width: 100%;
  }

  // Button
  .btn-next {
    min-width: 146px;
  }

  // Progress Tracker
  .step-progress {
    .progtrckr {
      list-style-type: none;
      padding: 0;
      width: 96%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      @include media-breakpoint-down(md) {
        width: 90%;
        margin-right: 2.5rem;
      }

      li {
        position: relative;
        width: 25%;
        text-align: left;
        line-height: 33px;
        cursor: default;

        &:after {
          content: '';
          position: absolute;
          top: 15px;
          left: 0;
          height: 1px;
          width: 100%;
          background: #c3c3c3;
          z-index: 1;
        }

        span {
          position: relative;
          display: inline-block;
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          color: #c3c3c3;
        }

        em {
          display: block;
          position: relative;
          color: #c3c3c3;
          font-weight: bolder;
          font-size: 18px;
          border-radius: 100%;
          width: 31px;
          height: 31px;
          line-height: 31px;
          font-style: normal;
          background: #ffffff;
          text-align: center;
          z-index: 2;
          box-shadow: 0 0 0 2px #c3c3c3;
        }

        // #Doing
        &.progtrckr-doing {
          color: $primary;

          em {
            box-shadow: 0 0 0 2px $primary;
            color: $primary-color;
          }

          span {
            color: $primary;
          }
        }

        // #Todo
        &.progtrckr-todo {
          color: silver;
        }

        // #Done
        &.progtrckr-done {
          color: $primary;

          &:after {
            background: $primary;
          }

          span {
            color: $primary;
          }

          em {
            box-shadow: 0 0 0 2px $primary;
            color: $primary-color;
          }
        }

        &:first-of-type {
          span {
            left: -7%;
          }
        }

        &:nth-of-type(2) {
          span {
            margin-left: -7%;
            @include media-breakpoint-only(xs){
              margin-left: -15%;
            }
          }
        }

        &:nth-of-type(3) {
          span {
            margin-left: -11%;
            @include media-breakpoint-only(xs){
              margin-left: -20%;
            }
          }
        }

        &:last-of-type {
          width: 0;
          em {
            left: 100%;
          }
          span {
            left: 100%;
          }
        }
      }
    }
  }

  .app-step-box {
    padding-top: 80px;
  }

  // Background
  .bg-premium-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;

    @include media-breakpoint-down(md) {
      min-height: 50px;
      height: 75px;
    }

    .slide-img-bg {
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-position: top left;
      background-size: cover;

      @include media-breakpoint-down(md) {
        background-position: center;
        min-height: 50px;
        height: 75px;
      }
    }

    .slider-content {
      text-align: center;
      margin-top: 80px;

      @include media-breakpoint-down(md) {
        margin-top: 14px;
      }

      .app-logo {
        margin: 0 auto 13px auto;
        width: 342px;
        height: 134px;
        background-repeat: no-repeat;
        background-image: url('~assets/utils/images/logo-new-XL.png');

        @include media-breakpoint-down(lg){
          background-image: url('~assets/utils/images/logo-new-lg.png');
          width: 246px;
          height: 110px;

          margin: 20px auto;
        }

        @include media-breakpoint-down(md) {
          background-image: url('~assets/utils/images/logo-full.svg');
          width: 342px;
          height: 49px;
          margin: 20px auto;
        }
      }

      h3 {
        color: white;

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
    }
  }

  .step-background {
    background: url('~assets/utils/images/account/lineBgSm.svg') right top no-repeat, white;
    background-size: 90%;
  }

  // Scroll Area - for manual extended UI
  .scroll-area-onboarding {
    margin: 0 -15px;
    padding: 0 15px;
  }
  .scroll-area-stage {
    overflow-x: hidden;
    min-height: calc(67.5vh - 55px);
  }
  // Stages
  .stage {
    position: relative;
    min-height: 50vh;
    margin-top: 50px;

    hr {
      border-top: 1px solid $primary;
    }

    h1 {
      font-size: 45px;
      line-height: 56px;
      font-weight: 900;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 35px;
      font-weight: 900;
      line-height: 44px;
      margin-bottom: 20px;
    }

    h6 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 900;
    }

    p {
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
    }

    .form,
    form {
      padding: 20px 0;

      label {
        font-weight: bold;
      }

      .form-group {
        .form-control {
          border: 1px solid $primary;
          border-radius: 0.35rem;
          color: $primary;

          &:focus {
            box-shadow: 0 0 0 1px rgba(23, 35, 84, 0.25);
          }

          &.is-invalid {
            border-color: #fc1502;
            background-size: 0.85em;
          }
        }
      }

      // MD design
      .col-md-6 {
        .form-group {
          margin-bottom: 2.5rem;

          label {
            display: inline-block;
            margin-bottom: 0.4rem;
            font-weight: bold;
          }
        }

        &:first-child {
          padding-right: 0.9em;

          @include media-breakpoint-down(md) {
            padding-right: 5px;
          }
        }

        &:last-child {
          padding-left: 0.9em;

          @include media-breakpoint-down(md) {
            padding-left: 5px;
          }
        }
      }
    }

    // [1] Stage One - Registration
    &.stage-one {
    }

    // [2] Stage Two - Verification
    &.stage-two {
    }

    // [3] Stage Three - Protection Level
    &.stage-three {
      .form-group {
        margin-bottom: 0;
      }
    }

    // [4] Stage four - Permissions
    &.stage-four {
      // Permission title
      .permission-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        position: relative;
        padding: 5px 0;
        margin: 0;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          background-color: $primary;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
        @include media-breakpoint-only(xs){
          .col{
            width: 33% !important;
          }
        }
      }

      // Form
      .form {
        padding: 15px;
      }

      // No list
      .no-list-col {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          font-size: 22px;
          line-height: 25px;
          font-weight: lighter;
          color: $gray-500;
        }
      }

      // Modal dialog
      .onboarding-modal {
        .rodal-mask {
          opacity: 0;
        }
        .rodal-dialog {
          box-shadow: 0 0.8rem 2.5rem rgba(52, 58, 64, 0.3);
          top: 25%;

          @include media-breakpoint-down(md) {
            left: 0;
          }

          @include media-breakpoint-down(sm) {
            top: 10%;
            width: 90% !important;
          }

        }
        .modal-footer{
          margin: 0 2rem;
          .send-invite-btn{
            right:-15px;
          }
        }
      }

      .userListRow {
        padding: 10px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid $gray-300;
        color: $primary;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
      }
    }

    // [5] Stage Five - Finish
    &.stage-five {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;

      h2 {
        padding-top: 10px;
      }
      .btn-lg.btn-wide {
        font-size: 16px;
        font-weight: 600;
      }
      // Success icon
      .sa-icon {
        transform: scale(1.15);
        &.sa-success {
          .sa-placeholder {
            border: 4px solid $success;
          }
        }
      }
    }
  }
  .finish-btns-wrap{
    @include media-breakpoint-down(md){
      margin-top: 20px;
    }
    @include media-breakpoint-only(xs){
      .go-dashboard-btn{
        margin: 0 10px 0 0;
      }
    }
  }
}

// Popover control
.staged-popover {
  p {
    margin-bottom: 0.5rem;
    margin-top: 0.4rem;
    &.invalid {
      color: #fc1502;
      font-weight: 500;
      &:before {
        position: relative;
        left: -5px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FC1502' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FC1502' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        content: '';
        padding-left: 1.5em;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: calc(0.55em + 0.375rem);
      }
    }

    &.valid {
      color: $dark-green;
      font-weight: 500;
      &:before {
        position: relative;
        left: -5px;
        content: '';
        padding-left: 1.5em;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: calc(0.55em + 0.375rem);
      }
    }
  }
  .bs-popover-bottom,
  .bs-popover-auto[x-placement^='bottom'] {
    top: 0.5rem !important;
  }
}

@media screen and (max-width: 1366px)and( min-width:1200px){
  .app-logo{
    transform: scale(0.7);
  }
}
@media screen and (max-width: 443px){
  .finish-btns-wrap{
      .go-dashboard-btn{
        display: inline-block;
        margin:10px auto !important;
      }
  }
}

