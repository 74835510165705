.settings {
  &.settings-home {
    a {
      svg {
        transition: all .26s ease;
      }

      .image {
        @include media-breakpoint-down(sm) {
          display: inline-block;
          max-width: fit-content;
        }
      }

      .content {
        left: -15px;
        padding-top: 1rem;

        @include media-breakpoint-down(sm) {
          display: inline-flex;
          align-items: center;
          width: 60%;
          margin: 0;
          padding: 0.5rem 0 0 0;
        }

        .card-title {
          @include media-breakpoint-down(sm) {
            font-size: 1rem;
            padding: 0;
            margin: 0;
          }
        }
      }

      .navigate-arrow {
        @include media-breakpoint-down(sm) {
          display: inline-block;
          max-width: fit-content;
        }
      }


      &:hover {
        text-decoration: none;

        .navigate-arrow {
          svg {
            transform: translateX(10px) scale(2);
            color: $blue;
          }
        }

        .image {
          animation: pulse 2s linear infinite;
        }
      }
    }

    @keyframes pulse {
      0% {
        filter: drop-shadow( 0 0 0 rgba(22, 25, 99, 0.25));
      }
      40% {
        filter: drop-shadow( 0 0 10px rgba(22, 25, 99, 0));
      }
      80% {
        filter: drop-shadow( 0 0 10px rgba(22, 25, 99, 0));
      }
      100% {
        filter: drop-shadow( 0 0 0 rgba(22, 25, 99, 0));
      }
    }
  }

  &.user-management {
    .card-header{
      @include media-breakpoint-down(md){
        padding: 0.75rem .5rem;
        margin: 0;
      }
      @include media-breakpoint-down(sm){
        padding: 0.3rem 0.5rem;
      }
    }
    .user-status-revoked {
      color: $light-gray;
    }
    .users-roles{
      padding: 0 5rem 3rem 5rem;
    }
    .users-table-wrap{
      overflow: auto;
      padding-top: 0;
      @include media-breakpoint-only(xs){
        &.card-body{
          padding-right: 0;
        }
      }
    }
    .user-table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 45px 0 10px;

      .user-table-title {
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
        color: $primary;
      }

      .settings-add-filter-btn {
        color: $blue;
        font-size: 14px;

        &:hover {
          color: $primary;
        }
        @include media-breakpoint-only(xs){
          font-size: 12px;
        }
      }
    }

    .table-responsive {
      min-height: calc(80vh - 320px);
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        min-height: 200px;
      }
    }
  }

  .inner-padding {
    padding: 0 17rem 3rem 7rem;
  }

  // Header
  .card-header {
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transition: all 0.26s ease;
        margin-right: 13px;
        margin-top: -3px;
      }

      &:hover {
        svg {
          transform: translateX(-5px) rotate(180deg);
        }
      }
    }
  }

  // Section
  .section {
    // Top header
    &.top-header {
      .image-col {
        max-width: 43px;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }
      .text-col {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;

        .heading {
          font-size: 18px;
          line-height: 23px;
          font-weight: 900;
          @include media-breakpoint-down(md){
            margin-top: 10px;
          }
        }
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
        }
      }
      &.settings-top-section{
        padding: 0 1rem;
        p{
          @include media-breakpoint-down(md){
            margin-bottom: 0;
          }
        }
      }
    }

    // Inner section
    &.inner-section {
      padding-left: calc(3rem + 10px);
      padding-top: 1rem;

      // Headings
      .heading {
        font-weight: 700;
        font-size: 18px;
      }

      .sub-heading {
        padding: 0.65rem 0 0.9rem;
      }

      // form-group
      .form-group {
        .checkbox-input {
          margin-bottom: 20px;
          .custom-radio {
            label {
              font-weight: bold;
            }
          }

          .sub-label {
            padding-left: 1.5rem;
            padding-top: 0.1rem;
          }
        }
      }
    }
  }

  // Single settings
  .setting {
    .label-text {
      font-weight: 800;
      line-height: 20px;
      padding: 0.25rem 0 0.1rem;
    }

    .under-indeterminate-handle {
      padding-left: 2rem;
    }

    .custom-checkbox {
      padding-top: 0.4rem;
      .custom-control-label {
        padding-left: 0.6rem;
        font-weight: 500;

        &:before,
        &:after {
          width: 1.1rem;
          height: 1.1rem;
        }

        &:after {
          transform: scale(1.1) translate(-1px, -2px);
        }

        &:before {
          top: 0;
        }
      }
    }

    .btn-download {
      text-align: left;
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        min-width: 300px;
      }
      @include media-breakpoint-only(xs){
        padding-left: 0;
        font-size: 12px;
      }
      span {
        width: 18px;
        text-align: center;
        display: inline-block;
        margin-right: 5px;

        svg {
          width: 14px;
          margin-top: -4px;
        }
      }
    }
    .malware-definitions{
      @include media-breakpoint-down(md){
        margin-bottom: 20px;
      }
    }
  }

  // Footer
  .card-footer {
    padding: 2rem 3rem;
  }

  .settings-card-body{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .settings-card-wrap{
    padding: .5rem 1.25rem ;
  }
  .card-title{
    margin-bottom: 5px;
  }
  .notifications-top-sections{
    @include media-breakpoint-down(md){
      padding: 0 3.5rem;
    }
    @include media-breakpoint-only(xs){
      padding: 0 1.5rem;
    }
  }
  .notification-method , .export-logs {
    display: block;
    @include media-breakpoint-only(xs){
      padding: 1rem !important;
    }
  }
  .alerts-logs-footer-wrap{
    padding-left: calc(3rem + 10px);
    padding-top: 1rem;
  }
 .card-header-tab{
   @include media-breakpoint-only(xs){
     padding-left: 0;
   }
 }
  .organization-settings-top-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(sm){
      align-items: flex-start;
    }
  }
  .software-update-top-section,.organization-settings-top-section{
    @include media-breakpoint-down(lg){
      padding-left: 4rem;
    }
    @include media-breakpoint-only(xs){
      padding-left: 1rem;
    }
  }
  .automate-definition{
    @include media-breakpoint-only(xs){
      padding-left: 1rem !important;
    }
  }
  .organization-settings-wrap{
    .inner-section{
      padding-left: calc(6rem + 10px);
      padding-right: 6rem;
      padding-top: 1rem;
      box-sizing: border-box;
      display: block;
    }
  }
}
.users-settings-header-wrap,.alerts-settings-header-wrap,.software-settings-header-wrap{
    .page-title-heading{
      div{
        .page-title-status{
          &.notice{
            @include media-breakpoint-only(xs){
              &:before{
                display: none;
              }
            }
          }
          @include media-breakpoint-only(xs){
            padding-left: 0;
          }
        }
      }
      @include media-breakpoint-only(xs){
        text-align: left;
      }
  }
}
.software-updates-wrap,.organization-settings-wrap{
  min-height: 75vh;
}

// settings-modal
.settings-modal {
  .rodal-mask {
    opacity: 0;
  }
  .rodal-dialog {
    box-shadow: 0 0.8rem 2.5rem rgba(52, 58, 64, 0.3);
    z-index: 1000;
    @include media-breakpoint-down(sm) {
      top: 10%;
      width: 90% !important;
    }
  }

  .modal-body {
    max-height: calc(70vh);
    overflow-y: auto;
  }

  .sub-select {
    padding: 0.5rem 0;
    @include media-breakpoint-down(sm) {
      padding: 0.5rem 0;
    }
    &.row {
      margin: 0;
    }

    .input-week-container,
    .input-hour-container {
      margin-right: 0.65rem;
      label {
        margin: 0 0 0.3rem;
      }
    }

    .input-week-container {
      width: 285px;
      @include media-breakpoint-down(sm) {
        flex: 1 0 100%;
      }
    }

    .input-hour-container {
      width: 110px;
      @include media-breakpoint-down(sm) {
        flex: 1 0 100%;
        padding-top: 10px;
      }
    }

    .input-email,
    .input-key {
      width: 430px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      label {
        flex: 0 1 100%;
      }
      input {
        flex: 1 0 50%;
      }
    }

    .react-select-container .react-select__control {
      min-height: 34px;
    }
  }

  .email-area {
    position: relative;
    &.mask {}
    &.disabled {
      position: relative;
      pointer-events: none;
      filter: grayscale(0.8);
      opacity: 0.5;
      transition: all 0.26s;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10;
      }

    }
  }
  .user-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 100%;
    justify-content: center;
    label {
      flex: 0 1 100%;
    }

    .userListRow {
      flex: 0 1 95%;
      border-bottom: 1px solid $gray-400;
      align-items: center;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      .userListEmail {
        font-size: 14px;
        font-weight: 700;
      }

      .actions-dropdown {
        .dropdown-toggle {
          float: right;
          padding-right: 0;
        }
      }
    }
  }

  .modal-header {
    margin-bottom: 0.25rem;
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-footer {
    //border-top: 1px solid $primary;
    margin-top: 0.25rem;
    border-top: 1px solid $primary;
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
    .ladda-button{
      @include media-breakpoint-only(xs){
        font-size: 0.7rem;
      }
    }
  }
  .user-list{
    .userListRow{
      .userListEmail{
        @include media-breakpoint-only(xs){
          max-width: 150px;

        }
      }
    }
  }
}

.user-edit-modal {
  .rodal-mask {
    opacity: 0;
  }
  &.rodal{
    position: absolute;
  }
  .rodal-dialog {
    box-shadow: 0 0.8rem 2.5rem rgba(52, 58, 64, 0.3);
    top: 15%;
    max-width: 90%;
    z-index: 1000;
    @include media-breakpoint-down(lg) {
      top: 20%;
      max-width: 60% !important;
    }

    @include media-breakpoint-down(md) {
      top: 20%;
      max-width: 70% !important;
    }
    @include media-breakpoint-down(sm) {
      top: 10%;
      left: -1rem;
      max-width: 340px !important;
      height: 505px !important;
    }
  }

  .modal-body {
    .form-input .form-group .form-control {
      height: calc(1.5em + 0.45rem + 4px);

      &::placeholder {
        font-size: 0.85rem;
      }
    }
    .react-select-container{
      .react-select__control {
        min-height: 34px;

        .react-select__placeholder {
          top: 54%;

          @include media-breakpoint-down(md) {
            top: 50%;
          }
        }
      }
    }
  }

  .sub-select {
    padding: 0.5rem 0;
    @include media-breakpoint-down(sm) {
      padding: 0.5rem 0;
    }
    &.row {
      margin: 0;
    }

    .react-select-container .react-select__control {
      min-height: 34px;
    }
  }

  .modal-header {
    margin-bottom: 0.25rem;
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
  }
  .modal-title {
    font-size: 20px;
    line-height: 25px;
  }
  .sub-modal-header {
    font-size: 14px;
    font-weight: 200;
    line-height: 20px;
    padding-top: 5px;
  }
  .modal-footer {
    //border-top: 1px solid $primary;
    margin-top: 0.25rem;
    border-top: 1px solid #C3C3C3;
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
    @include media-breakpoint-only(xs){
      padding: 1.7rem 0;
    }
  }
}
@media screen and (max-width: 1100px){
  .settings{
    &.settings-home{
      a{
        .content{
          left: 0
        }
      }
    }
  }
}

@media screen and (max-width: 992px){
  .settings{
    .card{
      .card-body{
        padding: 1.1rem;
      }
    }
  }
}

// adding padding between the content and the image right before the cards changes display
@media screen and (width: 768px){
  .settings{
     .card{
       .card-body{
         .content{
            padding-left: 30px;
        }
      }
    }
  }
}

//@media screen and (max-width: 767px){
//  .settings{
//    .card{
//      .card-body{
//        .image{
//          padding-bottom: 10px;
//        }
//        .content{
//          width: 90%;
//          display: inline-block;
//        }
//        .navigate-arrow{
//          display: inline-block;
//          flex: 0 0 0;
//          padding-right: 25px;
//        }
//      }
//    }
//  }
//}
//@media screen and (max-width: 576px) {
//  .settings{
//    .card{
//      .card-body{
//        .image{
//          padding-bottom: 0;
//        }
//        .content{
//          width: 90%;
//          display: inline-block;
//        }
//        .navigate-arrow{
//          display: inline-block;
//          flex: 0 0 0;
//          padding-right: 25px;
//        }
//      }
//    }
//  }
//}

