.easy-edit-wrapper {
  &.easy-edit-not-allowed {
    cursor: default;
  }

}

.easy-edit-inline-wrapper {
  &:focus {
    outline: none;
  }
  .easy-edit-component-wrapper {
    display: block;
    input{
      height: calc(1.5em + 0.45rem + 3px);
      border: 1px solid #161963;
      border-radius: 0.35rem;
      color: #161963;
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      background-color: #fff;
      background-clip: padding-box;
      transition: all .2s;

      &:focus {
        box-shadow: 0 0 0 1px rgba(23, 35, 84, 0.25);
        outline: none;
      }
    }
  }

  .easy-edit-button-wrapper {}
  .easy-edit-validation-error {
    width: 100%;
    margin-bottom: 0.1rem;
    font-size: 80%;
    color: #FC1502;
  }
}