
// Header Buttons

.header-btn-lg {
  padding: 0 0 0 ($layout-spacer-x);
  margin-left: ($layout-spacer-x);
  display: flex;
  align-items: center;
  position: relative;


  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }

}