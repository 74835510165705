/** Enroll Devices**/

// Button - test ui
.enroll-command {
  vertical-align: center;
  &:nth-of-type(2) {
    padding: 0 20px;
  }

  svg {
    margin-right: 5px;
    width: 21px;
    height: 21px;
  }
}

.enroll-command-btns{
  display: flex;
  justify-content: flex-end;
  @include media-breakpoint-down(md){
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 0;
    .enroll-command:nth-of-type(3){
      svg{
        margin-right: 0;
      }
    }
  }
}


// Enroll Table Status
.enroll-status {
  color: $primary;
  font-weight: bold;
  line-height: 18px;
  text-transform: capitalize;

  &.enroll-approved{
    color: $success;
  }
  &.enroll-rejected{
    color: $danger;
  }
  &.enroll-deauthorized {
    color: $light-gray;
  }
}

// Enroll loaded
.enroll-loader {
  font-weight: bold;
  .loader {
    width: 70px;
    height: 70px;
    position: relative;
    left: 0;
    right: 0;
    margin:  auto;
    .ball-scale-ripple-multiple {
      transform: translate(50%, 50%);
      transform-origin: center;
    }
  }

  .disabled-container {
    .loader {
      width: auto;
      height: auto;
    }
  }
}

.table-responsive{
  &.table-responsive-enroll{
     @include media-breakpoint-down(xs){
        svg{
           display: none;
        }
     }
  }
}

.table-top-enroll{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  &.empty-table-top {
    pointer-events: none;
    .enroll-new-device{
      pointer-events: visible;
      cursor: pointer;
    }
  }
  .enroll-new-device{
    background-color: $light-orange;
    padding:7px;
    text-align: center;
  }
  @include media-breakpoint-only(xs){
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
// Enroll Device Page
.enroll-device {
  min-height: 70vh;
  // Header
  .card-header {
    padding: 15px 0;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transition: all 0.26s ease;
        margin-right: 13px;
        margin-top: -3px;
      }

      &:hover {
        svg {
          transform: translateX(-5px) rotate(180deg);
        }
      }
    }
    @include media-breakpoint-down(sm){
      padding: 10px 5px;
      margin: 0;
    }
  }

  // Body
  .card-body {
    @include media-breakpoint-up(md) {
      padding: 0 7rem;
    }

    .top{
      margin-bottom: 17px;
    }

    .enroll-type {
      margin-bottom: 30px;

      .form-group {
        margin-left: 17px;
        .custom-radio {
          margin-bottom: 15px;
        }
        .custom-control-label {
          font-size: 14px;
          font-weight: 700;
          padding-left: 12px;
        }
      }
    }

    .method {
      display: flex;
      margin: 15px 0 0 40px;

      @include media-breakpoint-down(sm) {
        margin: 15px 0 0 0;
        display: block;
      }

      .btn-download {
        text-align: left;
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          min-width: 200px;
        }

        span {
          width: 35px;
          text-align: center;
          display: inline-block;

          .loader {
            .line-scale-pulse-out > div {
              width: 1px;
              height: 15px;
            }
          }

          .download_svg{
            margin-left: 2px;
          }
        }
      }

      .disabled-container {
        pointer-events: none;
        .btn-download {
          display: flex;
          margin-top: 7px;

          span {
            margin-left: 10px;
            margin-right: 5px;

          }
          font-size: 13px;
        }
        &.show {
          pointer-events: all;
          transition: all 0.36s;

          .btn-download {
            font-size: 13px;
            margin-top: 0;
            span {
              margin-left: 2px;
              margin-right: 0;
            }
          }
        }
      }

      .download-installer,.get-download-link {
        width: 210px;
        margin-right: 10px;
        height: 43px;
        display: inline-block;
        border: 1px solid $blue;
        text-align: center;
        padding-left: 0;

        &.btn-download{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    h5 {
      color: $primary;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 22px;
    }
  }

  // Footer
  .card-footer {
    padding: 1.5rem 2.5rem;
  }
  .number-circle{
    width: 27px;
    height: 27px;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $blue;
  }
  .enroll-license-span{
    color: $dark-blue;
    font-weight: bolder;
  }
  .enroll-license-span,.step-two-wrap{
    margin: 20px 0;
  }
  .close-enroll{
    width: 167px;
    height: 45px;
    margin: 0 10px 10px 0;
    font-weight: bold;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px){
  .table-top-enroll{
    .table-search-wrapper{
      .close{
        display: none;
      }
    }
  }
  .search-wrap{
    max-width: 100%;
    margin: 0 auto;
    .close{
      display: none;
    }
  }
}
@media screen and (max-width:673px) {
  .enroll-command-btns{
    align-items: center;
    .enroll-command{
      &:nth-of-type(1){
        .enroll-command-span{
          display: none;
        }
        &::after{
          content:("Approve");
        }
        svg{
          margin-right: 5px;
        }
      }
      &:nth-of-type(2){
        .enroll-command-span{
          display: none;
        }
        &::after{
          content:("Reject");
        }
        svg{
          margin-right: 5px;
        }
      }
      &:nth-of-type(3){
        min-width: 120px;
        justify-content: space-between;
        display: flex;
        .enroll-command-span{
          display: none;
        }
        &::after{
          content:("Enroll device");
        }
      }
    }
  }
}