// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('~assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('~assets/utils/images/logo.png');
}

.app-logo-full {
  height: 31px;
  width: 356px;
  background: url('~assets/utils/images/logo-all-caps.png') no-repeat;
}
.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  .top-container {
    margin-top: 1rem;

    h2 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
    }

    h3 {
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }

  .modal-content {
    z-index: 10;
    .modal-body {
      padding: 1.45rem;
      &.forgot-password-modal{
        padding: 1.6rem;
      }
    }
  }

  .form-check {
    .custom-checkbox {
      font-weight: 500;
      padding-left: 0.3rem;
    }
  }
  .password-input-wrap{
    position: relative;

    .show-password-icon{
      position: absolute;
      right: 10px;
      top: 9px;
      cursor: pointer;
      background: url("../assets/utils/images/show-password.svg") no-repeat;
      width: 20px;
      height: 20px;

      &.active {
        background: url("../assets/utils/images/show-password-active.svg") no-repeat;
      }
    }
  }
}


@media screen and (max-width: 360px) {
  .app-logo-full{
    position: relative;
    width: auto;
    background-size: contain;
  }
  .reset-password-btns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .reset-password-btn{
      margin-bottom: 5px;
    }
  }
}