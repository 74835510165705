// Input groups
.input-group {
  .input-group-prepend {
    div:not([class]) .react-datepicker__input-container,
    & + div .react-datepicker__input-container {
      .form-control {
        @include border-left-radius(0);
        @include border-right-radius($input-border-radius !important);
      }
    }
  }

  & > div:not([class]) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & .react-datepicker__input-container {
      .form-control {
        @include border-right-radius(0);
      }

    }
  }
}

.input-group {
  .input-group-prepend {
    & + div {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
    }
  }

  & > .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & > .react-datepicker__input-container > .form-control {
      @include border-right-radius(0);
    }
  }
}

// Forms Legend
legend {
  font-size: $font-size-base;
  font-weight: bold;
}

// Headings
.form-heading {
  font-size: $font-size-lg;
  margin: 0;
  color: $primary;

  p {
    color: $gray-600;
    padding: 0.3rem 0 0;
    font-size: $font-size-base;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

// Form input
.form-input {
  label {
    font-weight: 600;
  }

  .form-group {
    .form-control {
      height: calc(1.5em + 0.45rem + 3px);
      border: 1px solid $primary;
      border-radius: 0.35rem;
      color: $primary;
      &:focus {
        box-shadow: 0 0 0 1px rgba(23, 35, 84, 0.25);
      }
      &.is-invalid {
        border-color: #fc1502;
        background-size: 0.85em;
        background: none;
      }
    }
  }
}

// custom select options
.react-select-container {

  // Value Container
  .react-select__value-container {
    .react-select__single-value {
      font-size: 14px;
      color: $primary;
    }
  }

  // Select control
  .react-select__control{
    border: 1px solid $primary;
    border-radius: 0.35rem;
    color: $primary;
    &--is-focused {
      box-shadow: 0 0 1px 0 $primary;
    }
    &--menu-is-open{
      box-shadow: 0 0 2px 0 $primary;
    }
  }

  .react-select__indicator-separator {
    background-color: transparent;
  }

  // Indicator
  .react-select__indicator  {
    padding: 4px 8px;
    color: $primary;
  }

  // Menu
  .react-select__menu {
    border: 1px solid $primary;
    font-size: 13px;

    .react-select__menu-list {
      .react-select__option {
        &:hover {
          background-color: transparent;
          color: $blue;
          font-weight: bold;
        }
        &--is-focused {
          background-color: transparent;
        }
        &--is-selected {
          background-color: $primary;
        }
      }
    }
  }

  &.invalid {
    // Select control
    .react-select__control{
      border: 1px solid $danger;
      border-radius: 0.35rem;
      color: $danger;
      &--is-focused {
        box-shadow: 0 0 1px 0 $danger;
      }
      &--menu-is-open{
        box-shadow: 0 0 2px 0 $danger;
      }
    }

    // Indicator
    .react-select__indicator  {
      padding: 4px 8px;
      color: $danger;
    }

    ~ .invalid-feedback {
      display: block;
    }
  }
  ~ .invalid-feedback {
    display: none;
  }
}

// Customs
.invalid-callback-feedback {
  color: #fc1502;
  font-size: 0.85em;
  padding: 0 2.5em;
  margin: 0.65em 0 -0.65em 0;
  line-height: 1;
  background: none;
}