.dashboard {
  // - Card
  .flatten {
    &.card {
      min-height: 100%;
      position: relative;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .no-data {
          text-align: center;
          font-size: 1.45rem;
        }
      }
    }
  }

  // -- Title
  .card-title {
    font-size: 18px;
    font-weight: 800;
    text-transform: capitalize;
    @include media-breakpoint-down(sm){
      margin-bottom: 5px;
    }
  }

  // -- Text
  .card-text {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 7px 0;

    span {
      font-weight: 700;
      position: relative;

      &.up,
      &.down,
      &.neutral {
        &:before {
          position: absolute;
          font-weight: 500;
          font-size: 18px;
          left: -15px;
          top: 0;
          width: 15px;
          height: 100%;
        }
      }

      &.up {
        &:before {
          content: '\01F851';
          color: $danger;
          animation: bounce 2s reverse 3 forwards;
        }
      }

      &.down {
        &:before {
          content: '\01F853';
          color: $success;
          animation: bounce 2s 3 forwards;

        }

      }

      &.neutral {
        &:before {
          content: '-';
          color: $gray-600;
        }
      }

      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-7px);
        }
        60% {
          transform: translateY(-3px);
        }
      }
    }
    &.security-span{
      margin-top: 20px;
    }

    &.security-span,
    &.infected-span {
      padding: 0 0 0 1.25rem;
      text-align: left;
      display: inline-block;

      .btn {
        padding: 0;
      }
    }
  }
  .apexcharts-legend{
    margin-bottom: 10px;
  }

  // Info
  .btn-icon.btn-link {
    color: $gray-500;
  }

  // - Blocks

  // -- Overview
  .overview {
    font-size: 18px;
    font-weight: 400;
    color: $primary;

    hr {
      background: #545aeb;
      margin: 15px 0 25px;
    }

    .num {
      font-size: 30px;
      line-height: 38px;
      font-weight: 900;
    }

    .pro {
      display: flex;
      padding-bottom: 23px;
      .subtext {
        font-size: 16px;
        line-height: 9px;
      }

      .icon {
        //width: max-content;
        //float: left;
        padding-right: 18px;
      }

      .text {
        //float: left;
      }
    }
  }

  // -- Infected
  .infected {
    // Chart
    .radial-bar-infection {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .chart {
        margin: 0;
        padding: 0;
        max-width: 230px;

        .apexcharts-svg {
          margin: 0;
          padding: 0;
          .apexcharts-radialbar {
            margin: 0;
            padding: 0;
            @include media-breakpoint-up(lg) {
              transform: translateY(-20px) translateX(-20px) scale(1.3);
            }

            @include media-breakpoint-down(lg) {
              transform: translateY(-20px) translateX(-15px) scale(1.3);
            }
          }
        }
      }
      .overall {
        width: 70%;
        .num {
          font-size: 30px;
          font-weight: 900;
        }
      }
      @include media-breakpoint-down(xl) {
        .chart {
          max-width: 350px;
          min-width: 200px;
          width:65%;
          top: -10px;
          svg{
            min-height: 550px;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        .chart {
          top: -40px;
          max-width: 550px;
          width: 75%;
        }
        .overall{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .num{
            margin-right: 10px
          }
        }
        text-align: center;
      }
      @include media-breakpoint-down(md) {
        .chart {
          max-width: 100%;
          margin-right: 30px;
          width: 45%;
          min-width: 200px;
          svg{
            min-height: 550px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .chart {
          max-width: 100%;
          margin-top: 40px;
          margin-right: 0;
          width: 45%;
          svg{
            min-height: 550px;
          }
        }
      }
    }

    .num {
      font-size: 30px;
      line-height: 38px;
      font-weight: 900;
    }

    .text {
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
    }
  }

  // -- Action-required
  .action-required {

    .action-required-container {
      margin: auto;

      .action-required-block {
        display: flex;
        justify-content: center;
        align-items: center;

        .num {
          font-size: 56px;
          font-weight: 900;
          padding-top: 10px;
          padding-left: 20px;
        }
      }
    }

  }

  // -- Score
  .score {
      .donut {
        max-width: 400px;
        margin: auto;
        width: 99%;

        .apexcharts-legend {
          //justify-content: space-evenly;
          //@include media-breakpoint-down(lg) {
          //  top: 20px !important;
          //}
          //
          //@include media-breakpoint-down(md) {
          //  top: 20px !important;
          //}
          //
          //@include media-breakpoint-down(sm) {
          //  top: 25px !important;
          //}
          //
          //@include media-breakpoint-down(xs) {
          //  top: 0 !important;
          //}
          //
          //@include media-breakpoint-up(xxl) {
          //  top: 25px !important;
          //}
          //
          //@include media-breakpoint-up(xl) {
          //  top: 0 !important;
          //}

          .apexcharts-legend-series{
            &:not(&:first-of-type) {
              @include media-breakpoint-down(xxl) {
                margin-bottom: 5px !important;
              }

              @include media-breakpoint-down(lg) {
                margin-bottom: 10px !important;
              }

              @include media-breakpoint-down(md) {
                margin-bottom: 10px !important;
              }

              @include media-breakpoint-down(sm) {
                margin-bottom: 5px !important;
              }

              @include media-breakpoint-down(xs) {
                margin-bottom: 2px !important;
                transform: scale(0.9);
              }

              @include media-breakpoint-up(xxl) {
                margin-bottom: 25px !important;
              }

              @include media-breakpoint-up(xl) {
                margin-bottom: 5px !important;
              }
            }
          }
        }
      }
  }

  // - OverTime
  .overtimeCharts {
    .sub-title {
      font-size: 11px;
      font-weight: 300;
      margin-top: -5px;
    }

    .react-select-container {
      .react-select__control {
        min-height: 31px;
        height: 31px;
        font-size: 13px;
        line-height: 16px;
        font-weight: bold;
      }

      .react-select__value-container {
        .react-select__single-value {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width:1140px)and (min-width: 992px){
  .dashboard{
    .security-score-wrap{
      margin-top: 10px;
      flex:0 0 100%;
      max-width: 100%;
      padding-left: 1rem !important;
    }
    .infected-wrap,.overview-wrap{
      flex:0 0 50%;
      max-width: 50%;
      padding-right: 1rem !important;
    }
  }
}
@media screen and (max-width:991px){
  .overview-wrap,.infected-wrap{
    margin-bottom: 20px;
  }
}
@media screen and (max-width:360px){
  .score{
    .apexcharts-legend{
      justify-content: flex-start !important;
      .apexcharts-legend-series{
        margin-bottom: 20px !important;
        &:nth-last-of-type(1){
          position: absolute;
          bottom: -20px;
        }
      }
    }
  }
}
