.charts-bar {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;

  .no-padding {
    padding: 0;
  }

  .chart-bar {
    &:not(&:first-child) {
      margin-top: 5px;
    }
    display: flex;
    flex-grow: 1;
  }

  .chart-bar-label {
    width: 150px;
  }

  .bar-char-input {
    width: calc(100% - 150px);
    overflow: hidden;
  }

  .chart-bar-grow {
    position: relative;
    height: 30px;
    margin-bottom: 10px;
    opacity: 0;

    animation: 1s anim-lightspeed-in ease forwards;

    .chart-bar-value {
      position: absolute;
      right: -25px;
      line-height: 30px;
      color: $primary;
      font-weight: 900;

      &.hundred {
        right: 10px;
        color: white;
      }
    }
  }

  .bar-color-0 {
    background: linear-gradient(to right, #545aeb, #545aeb);
    animation-delay: 0.2s;

  }
  .bar-color-1 {
    background: linear-gradient(to right, #ff7d4d, #ff7d4d);
    animation-delay: 0.4s;
  }
  .bar-color-2 {
    background: linear-gradient(to right, #ffa222, #ffa222);
    animation-delay: 0.6s;
  }
  .bar-color-3 {
    background: linear-gradient(to right, #74e2b4, #74e2b4);
    animation-delay: 0.8s;
  }
  .bar-color-4 {
    background: linear-gradient(to right, #78c6ff, #78c6ff);
    animation-delay: 1s;
  }
  .bar-color-5 {
    background: linear-gradient(to right, #ffd191, #ffd191);
    animation-delay: 0.2s;
  }
  .bar-color-6 {
    background: linear-gradient(to right, #badef8, #badef8);
    animation-delay: 0.4s;
  }
  .bar-color-7 {
    background: linear-gradient(to right, #747b98, #747b98);
    animation-delay: 0.6s;
  }

  .bar-color-8 {
    background: linear-gradient(to right, #d8d9e3, #d8d9e3);
    animation-delay: 0.8s;
  }

  .bar-color-9 {
    background: linear-gradient(to right, #d8d9e3, #d8d9e3);
    animation-delay: 1s;
  }

  .bar-color-10 {
    background: linear-gradient(to left, #121a69, #121a69);
    animation-delay: 1s;
  }

  //Keyframes
  @keyframes anim-lightspeed-in {
    0% {
      transform: translateX(-200%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
