// TODO : MOVE from here
.react-bootstrap-table-pagination {
  margin: 0;
  flex-direction: row-reverse;

  .react-bootstrap-table-pagination-total {
    text-align: right;
    float: right;
    color: $primary;
  }

  .react-bootstrap-table-pagination-list {
    .react-bootstrap-table-page-btns-ul {
      .page-item {
        border: none;
        .page-link {
          border: none;
          padding: 0.5rem 0.5rem;

          &:hover {
            background: transparent;
            color: $blue;
            font-weight: bolder;
          }
        }
        &.disabled {
        }
        &.active {
          color: $primary;
          background: transparent;
          font-weight: bolder;
          .page-link {
            color: $primary;
            background: transparent;
            font-weight: bolder;
          }
        }
      }
    }
  }
}

// Devices
.devices-table {
  // Mark deauthorized device
  .deauthorized {
    color: $gray-600 !important;

    .deauthorized {
      padding: 0 5px;
      font-size: 75%;
      text-space: 1px;
    }
  }
}
.hidden{
  display: none;
}
.device-filtering {
  display: flex;
  flex: 1;
  justify-content: center;
  .btn-filter {
    margin-right: 5px;
    width: calc(20% - 4px);
    min-width: 130px;

    &:last-of-type {
      margin-right: 0;
    }
    height: 100px;
    @include media-breakpoint-up(sm) {
      flex: 1;

      &:nth-of-type(even){
        margin-right: 5px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 0 !important;
      &:nth-of-type(3) {
        margin-right: 5px;
      }
    }

    @include media-breakpoint-only(xs){
      font-size: 11px;
      height: 70px;
      flex: 1;
      margin-top: 5px;

      &:nth-of-type(even) {
        margin-right: 0;
      }
      .btn-vertival-text {
        font-size: 16px;
      }
    }
  }
}

// Single Device details
.device-details {
  .card-detail {
    min-height: 100%;
    .headers{
      display: flex;
      max-width: 80%;
     .actions-header{
       margin-left: auto;
     }
    }
  }
  // Details
  .device-detail {
    display: flex;
    width: 100%;
    color: $primary;
    padding: 0 1.8rem 1.25rem 1.8rem;
    .row{
      width: 100%;
    }
    .detail {
      line-height: 18px;
      font-size: 14px;
      margin-bottom: 8px;
      padding: 0;
      font-weight: 400;

      &.text-bold {
        font-weight: bold;
      }

      &.text-light {
        font-weight: lighter;
      }
    }

    .sub-detail {
      margin-top: 1.25rem;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
    }

    .command-row {
      position: relative;
      min-width: 300px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0rem 1rem 0 1rem;
      height: 100%;
      border-left: 1px solid $light-gray;
      .sub-header{
        font-size:14px;
        color: $sub-header-gray;
        margin-bottom: 10px;
        font-weight: bold;
      }

      .col {
        justify-content: flex-start;
        margin-top: 0;

        &:nth-child(2) {
          position: relative;
          display: flex;

          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }

        &:nth-child(3) {
          svg {
            margin-top: -2px;
          }
        }
      }

      .btn-icon {
        font-weight: bold;
        font-size: 14px;
        svg {
          vertical-align: middle;
          margin-right: 12px;
        }
      }
    }
  }

  // Security score
  .security-score {
    .card-body {
      margin-top: -15px;
    }

    .security-score-chart {
      max-width: 255px;
      margin: 0 auto;
      text-align: center;
    }

    // No chart
    .noChart {
      display: flex;
      flex-direction: column;
      .security-score-chart {
        height: 100%;
        margin: auto;
        max-width: 100%;

        .no-chart {
          font-size: 26px;
          line-height: 32px;
          font-weight: bolder;

          @include media-breakpoint-down(md) {
            font-size: 27px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 18px;
          }

        }
      }
    }
  }

  // Event List
  .device-events-list {
    display: block;
    margin: 0.1rem 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.5rem 1rem;

    .device-event-item {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #ebebeb;

      div {
        display: inline-block;
      }

      .badge {
        min-width: 10px;
        min-height: 10px;
      }
      .event-type {
        min-width: 35%;
        text-transform: capitalize;
        font-weight: bold;
      }
      .event-information {
        min-width: 35%;
        padding-left: 25px;
        text-transform: capitalize;
      }
      .event-date {
        min-width: 25%;
        text-align: left;
        float: right;
      }
    }

    // timeline
    .vertical-timeline-element-content {
      margin-left: 220px;
      font-size: 1rem;

      .vertical-timeline-element-date {
        display: block;
        position: absolute;
        left: -220px;
        font-size: 0.9rem;
      }

      .timeline-title {
        font-size: 1rem;
        text-transform: capitalize;
        margin: 0;
      }
    }

    .vertical-timeline-element-icon {
      position: absolute;
      top: 0;
      left: 194px;
    }

    .vertical-timeline::before {
      left: 200px;
    }

    // No events
    .no-events {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      p {
        display: block;
        font-size: 22px;
        font-weight: 800;
      }
    }
  }

  // Policy List
  .device-policies-list {
    padding-top: 10px;
    &.scroll-area-lg {
      height: 401px;
    }
    .list-group {
      .list-group-item {
        background: #f2f4f6;
        padding: 0.75rem 1.25rem;
        margin: 0 1rem 0.75rem;
        border-radius: 0.25rem;
        border: none;
        @include media-breakpoint-down(xl){
          padding: 0.75rem 0.6rem;
        }
      }
    }
  }
}

.widget-content-wrapper {
  .widget-content-left {
    padding-left: 10px;
  }
}
.mobile-actions-wrap{
  text-align: right;
  padding-bottom: 20px;
  .table-actions-mobile-toggle{
    top: 10px;
    right: 10px;
    background-color: #161963;
    border-radius: 25px;
    color: white;
    font-family: 'muli';
    padding: 7px 15px;
    outline: 0;
    border: 0;
    &:after{
      transform: rotate(90deg);
      margin-left: 10px;
    }
    @include media-breakpoint-down(sm){
      display: inline-block;

    }
    &[aria-expanded="true"]{
      background-color: #055FF0;
      color: white;
    }

  }
  #table-actions-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .command-button{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: auto;
      border-bottom: 1px #B6B6B6 solid;
      width: 100%;
      height: 50px;

      &:nth-of-type(1){
        border: 0;
        border-bottom: 1px #B6B6B6 solid;
        padding-top: 0;
      }
      &:nth-of-type(3){
        border: 0;
        padding-bottom: 0;
      }
      svg{
        margin-right: 10px;
      }
    }
  }
  @include media-breakpoint-only(xs){
    padding-bottom: 10px;
  }
  &.device-mobile-actions{
    display: flex;
    padding-bottom: 0;
    margin-top: 10px;
    justify-content: flex-end;
    .dropdown-menu{
      #table-actions-mobile{
        .col{
          &:nth-of-type(3){
            .command-button{
              border-bottom: 0;
            }
          }
          &:nth-of-type(2){
            .command-button{
              .version-muted-text{
                margin:0 0 0 5px;
              }
            }
          }
        }
      }
    }
    .table-actions-mobile-toggle{
      margin-right: 10px;
      padding: 10px;
    }
  }
}

// TODO: Refactor and split, multiple usage
.table-top {
  display: flex;
  justify-content: space-between;
  .table-actions {
    display: flex;
    justify-content:flex-end;
    text-align: center;
    .btn-filter {
      width: 20%;
      min-width: 130px;
      height: 100px;
    }
    .command-button{
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      svg{
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    @include media-breakpoint-only(xs){
      .command-button{
        display: flex;
        align-items: center;
        font-size: 12px;
        svg{
          width: 20px;
          height: 20px;
        }
      }
    }
@include media-breakpoint-down(sm){
  position: absolute;
  right: 165px;
  top: 38px;
}
  }


  .devices-export-cvs-wrap {
    border-left: #000000 1px solid ;
    max-width: 170px;
    text-align: right;
   @include media-breakpoint-down(sm){
     margin-left: auto;
     display: inline-block;
     float: right;
     margin-top: 5px;
     margin-bottom: 10px;
     border-left: 0;
   }
  }
  .devices-search-input {
    margin-top: 10px;
    @include media-breakpoint-down(sm){
     .close{
       display: none;
     }
    }
  }
  &.empty-table-top {
    pointer-events: none;
  }

  &.devices-table-top{
    @include media-breakpoint-down(sm){
      padding: 0;
    }
  }
}

// --- Media screen Tweaks
// making polices a into a col
@media screen and (max-width: 1186px) {
  .device-details {
    .device-policies-list {
      .list-group {
        position: relative;
        .list-group-item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .widget-content-wrapper {
            position: relative;
            width: 100%;
            flex-direction: column;
            .btn.btn-pill {
              margin-right: auto;
              position: relative;
            }
            .modify-policy-btn{
              @include media-breakpoint-down(lg){
                margin-top: 10px;
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .table-actions{
    flex: 0;
  }
}

@media screen and (max-width: 729px){
  .device-filtering{
    .btn-filter:nth-of-type(5){
      margin-top: 5px;
    }
  }
}
@media screen and (max-width: 599px){
  .device-filtering{
    .btn-filter:nth-of-type(4){
      margin-top: 5px;
    }
  }
}
@media screen and (max-width: 464px){
  .device-filtering{
    .btn-filter:nth-of-type(1){
      margin-right: 5px;
      margin-left: 5px;
    }
  }
}
@media screen and (max-width: 575px){
  .device-filtering{
    .btn-filter:nth-of-type(5),.btn-filter:nth-of-type(3){
      margin-left:5px;
    }
  }
  .card-detail-wrap{
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 360px){
  .device-filtering .btn-filter:nth-of-type(1){
   order: 5;
    margin-left: 0;
    margin-right: 0;
  }
  .device-filtering .btn-filter[data-event="pendingApproval"]{
    margin-right:0;
  }
  .device-details{
    .device-detail{
      .command-row{
        justify-content:center;
      }
    }
    .device-events-list{
      margin: 0;
      padding-right:0 ;
      .vertical-timeline-element-content{
        margin-left: 25px;
        .timeline-title{
          margin-bottom: 20px;
        }
        .vertical-timeline-element-date{
          left: 1px;
          top: 20px;
        }
      }
      .vertical-timeline{
        &:before{
          left:5px;
        }
      }
      .vertical-timeline-element-icon{
        left: 0;
      }
      .device-event-item{
        padding: 0.5rem 0;
        .device-event-item{
          padding: 0;
        }
        .event-information{
          min-width: 28%;
          padding-left: 0;
        }
        .event-type{
          min-width: 33%;
        }
      }
    }

  }
  .device-detail{
    .row{
      .col{
        &:nth-of-type(2){
          flex-basis: auto;
          margin-top: 15px;
        }
      }
    }
  }
  .device-title{
    .app-page-title{
      padding: 2rem 0 1rem 0 ;
    }
  }
}
@media screen and (max-width: 768px){
  .device-details{
    .command-row{
      .col{
        padding: 0;
        &:before,&:after{
          background-color: transparent !important;
        }
        &:nth-of-type(2){
          padding-left: 0.7rem;
          .version-muted-text{
            margin: 0 0 0 6px;

          }
        }
      }
    }
    .device-events-list{
      padding: 0.5rem 0;
    }
  }
}

//for adding margin when divs take 12 cols
@media screen and (max-width: 767px) {
  // single device
  .device-details {
    .card {
      margin-bottom: 20px;
    }

    .device-policies-list {
      .list-group {
        position: relative;
        .list-group-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .widget-content {
            width: 100%;
            .widget-content-wrapper {
              position: relative;
              display: flex;
              flex-direction: row;
              width: 100%;
              .widget-content-left {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  // devices
  .devices-table {
    .table-search-wrapper {
      margin-bottom: 5px;
    }
    .table-actions {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .export-cvs-wrap {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width:850px) {
  .device-details{
    .device-detail{
      padding:1.8rem 1.25rem;
      .command-row{
        border-left: none;
      }
    }
    .card-detail{
      .headers{
        display: none;
      }
    }
  }
}

@media screen and (max-width:575px) {
  .device-img{
    flex: 0 0 100%;
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 456px) {
  // Devices
  .device-table {
    .table-top {
      .table-actions {
        button {
          display: flex;
          flex-direction: column;
          svg {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}

//making the command row responsive
@media screen and (max-width: 691px) {
  // Device details
  .device-details {
    .device-detail {
      .command-row {
        .col:nth-of-type(2) {
          &:before,
          &:after {
            display: none;
          }
        }
        .col {
          text-align: left;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

// making the name and device icon in the table in separate rows
@media screen and (max-width: 650px) {
  // Devices
  .device-table {
    .react-bootstrap-table {
      td:nth-of-type(2) {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

// adding space under the laptop image
@media screen and (max-width: 400px) {
  // Device details
  .device-details {
    .device-detail {
      .device-img {
        margin-bottom: 10px;
      }
    }
  }
}

// spacing the items in the polices
@media screen and (max-width: 382px) {
  // Device details
  .device-details {
    .list-group {
      position: relative;
      .list-group-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 5px !important;

        .widget-content {
          width: 100%;
          .widget-content-wrapper {
            justify-content: space-around;
            position: relative;
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 0;
            .widget-content-left {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}
