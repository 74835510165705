.notfound {
  min-height: 100%;

  .bg-premium-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .slide-img-bg {
      min-height: calc(100vh - 150px);
      flex: 1;
      height: 100%;
      width: 100%;
      background-position: top left;
      background-size: cover;

    }

    .slider-content {
      color: #fff;
      text-align: center;
      margin-top: 5.2vh;

      .four-zero-four {
       font-size: 20vh;
       font-weight: 900;
       line-height: 1;
     }

      .not-found-text{
        font-size: 4.1vh;
        font-weight: 900;
        line-height: 1.5;
      }

      .fall-of-rome {
        mix-blend-mode: hard-light;
        margin: 3.5vh auto;
        height: 32vh;
        width: 90%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .logo-full {
        width: 90%;
        height: 4vh;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
      }
    }
  }

  // Content
  .notfound-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem 1.5rem;

    h3 {
      font-size: 25px;
      line-height: 31px;
      font-weight: 900;
    }

    .error-list {
      .title {
        font-size: 18px;
        font-weight: bold;
      }

      ul {
        margin-top: 5px;
        list-style-type: none;
        font-weight: 500;

        li {
          text-indent: -18px;
          &:before {
            content: "\2013";
            margin-left: -5px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  // Footer
  .footer {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    margin-top: 10px;
  }
}