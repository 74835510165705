.chart-donut {
  --donut-size: 150px;
  --donut-border-width: 22px;
  --donut-spacing: 0;
  --donut-spacing-color: 255, 255, 255;
  --donut-spacing-deg: calc(1deg * var(--donut-spacing));
  --donut-label-size: calc(var(--donut-size) - var(--donut-border-width) - 20px);
  --donut-label-font-size: calc(var(--donut-size) / var(--donut-border-width) + 50px);
  --label-color: #172354;
  --animation-speed: 2s;
  --countTo: 100;
  border-radius: 50%;
  height: var(--donut-size);
  width: var(--donut-size);
  margin: 40px;
  position: relative;
  z-index: 1;

  // Label
  .donut_label__container {
    position: absolute;
    z-index: 3;
    width: var(--donut-label-size);
    height: var(--donut-label-size);
    transform: scale(1) translate(-50%, -50%);
    background: white;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.27);
  }

  .donut__label {
    left: 50%;
    line-height: 1.5;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    .donut__label__heading {
      font-size: var(--donut-label-font-size) px;
      font-weight: 600;
      color: var(--label-color);
    }

    .donut__label__sub {
      color: #666666;
      font-size: 75%;
      letter-spacing: 0.05em;
    }

    .donut__label__percentage {
      color: var(--label-color);
      font-size: 200%;
      font-weight: 900;
    }
  }

  // Slice
  .donut__slice {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;

    &::before,
    &::after {
      border: var(--donut-border-width) solid rgba(0, 0, 0, 0);
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      width: 100%;
    }

    &::before {
      border-width: calc(var(--donut-border-width) + 1px);
      box-shadow: 0 0 1px 0 #{'rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)))'};
    }
  }

  // Calc colors and data
  $donut-slices: (
    first: #545AEB,
    second: #FF7D4D,
    third: #FFA222,
    fourth: #74E2B4,
    fifth: #78C6FF,
    six: #FFD191,
    seven: #BADEF8,
    eight: #747B98,
    nine: #D8D9E3,
    ten: #8DC3B6,
  );

  $slides-concat: '';

  @each $slice in $donut-slices {
    $name: nth($slice, 1);
    $color: nth($slice, 2);
    $i: index($donut-slices, $slice);

    @if $i > 1 {
      .donut__slice__#{$name} {
        --#{$name}-start: calc(#{$slides-concat});
        --#{$name}-check: max(calc(var(--#{$name}-start) - 0.5), 0);
        clip-path: inset(0 calc(50% * (var(--#{$name}-check) / var(--#{$name}-check))) 0 0);
      }
    } @else {
      .donut__slice__#{$name} {
        --#{$name}-start: 0;
      }
    }

    .donut__slice__#{$name}::before {
      border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
      animation: #{$name}-anim-before var(--animation-speed) forwards;
    }

    @keyframes #{$name}-anim-before {
      from {
        transform: rotate(360deg) scale(0.8);
      }
      to {
        transform: rotate(calc(360deg * var(--#{$name}-start) + 45deg));
      }
    }

    .donut__slice__#{$name}::after {
      border-top-color: rgba($color, 1);
      border-right-color: rgba($color, calc(100 * (var(--#{$name}) - 0.25)));
      border-bottom-color: rgba($color, calc(100 * (var(--#{$name}) - 0.5)));
      border-left-color: rgba($color, calc(100 * (var(--#{$name}) - 0.75)));
      animation: #{$name}-anim var(--animation-speed) forwards;
    }

    @keyframes #{$name}-anim {
      from {
        transform: rotate(360deg) scale(0.8);
      }
      to {
        transform: rotate(calc(360deg * var(--#{$name}-start) + 45deg + var(--donut-spacing-deg)));
      }
    }

    @if $i == 1 {
      $slides-concat: var(--#{$name});
    } @else {
      $slides-concat: $slides-concat + ' + ' + var(--#{$name});
    }
  }
}
