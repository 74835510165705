// Buttons

// 2x Outline Border
.btn-outline-2x {
  border-width: 2px;
}

.btn-group {
  .btn {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .btn-outline-2x + .btn-outline-2x {
    margin-left: -2px;
  }

  .btn-square {
    @include border-radius(0);
  }
}

.btn {
  font-size: 0.8rem;
  font-weight: 500;

  // Pills Buttons
  &.btn-pill.btn-wide,
  &.btn-pill {
    @include border-left-radius(50px);
    @include border-right-radius(50px);
  }
}

// Dashed Border
.btn-dashed {
  border-style: dashed;
}

// Icon Buttons
.btn-icon {
  vertical-align: bottom;

  &.btn-icon-right {
    .btn-icon-wrapper {
      margin-left: ($spacer / 2);
      margin-right: 0;
    }
  }

  .btn-icon-wrapper {
    margin-right: ($spacer / 2);
    margin-left: 0;
    margin-top: 0;
    font-size: 17px;
    vertical-align: middle;
    transition: color 0.1s;
    display: inline-block;
  }

  &.btn-link {
    text-decoration: none;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }

  &.btn-blank {
    text-decoration: none;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }

  &.btn-lg:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 25px;
    }
  }

  &.btn-sm:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 16px;
    }
  }

  &.btn-pad-icon {
    svg {
      margin-right: 1em;
    }
  }
}

.btn-icon-only {
  .btn-icon-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  &.active {
    color: $blue;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 60%;
      height: 1px;
      background-color: $blue;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.bg-transparent.active {
  .btn-icon-wrapper{
      &::after {
        content: '';
        position: absolute;
        width: 60%;
        height: 1px;
        background-color: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  &::after {
    content: '';
    position: absolute;
    width: 60%;
    height: 1px;
    background-color: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

// Hover Shine
.btn-hover-shine {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }

  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.4s ease-in-out;
  }

  &.btn-pill {
    &::after {
      @include border-left-radius(50px);
      @include border-right-radius(50px);
    }
  }
}

// Vertical Icons {
.btn-icon-vertical {
  padding-top: ($btn-block-spacing-y * 2);
  padding-bottom: ($btn-block-spacing-y * 2);

  .btn-icon-wrapper {
    display: block;
    font-size: 200%;
    margin: 5px 0;
    opacity: 0.6;
  }

  &.btn-link {
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }

  &.btn-lg:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 25px;
    }
  }

  &.btn-sm:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 16px;
    }
  }

  &:active,
  &.active,
  &:hover {
    .btn-icon-wrapper {
      opacity: 1;
    }
  }

  &.btn-icon-bottom {
    .btn-icon-wrapper {
      margin: ($spacer / 5) 0 5px;
    }
  }

  &.btn-transition-text {
    .btn-icon-wrapper {
      transition: all 0.2s !important;
    }

    &:hover {
      .btn-icon-wrapper {
        transform: scale(1.3);
      }
    }

    &.btn-transition-alt {
      &:hover {
        .btn-icon-wrapper {
          color: $white !important;
        }
      }
    }
  }
}

.btn-icon-lg {
  font-size: $h1-font-size !important;
}

// Buttons Transitions
.btn-transition {
  color: $gray-600;
  border-color: $gray-200;
  background-color: none;

  &.btn-outline-link {
    border-color: transparent;
    background-color: transparent;

    &:hover {
      color: $primary;
      background: $gray-100;
    }
  }

  &.disabled,
  &:disabled {
    color: $gray-600;
    border-color: $gray-200;
  }

  &:hover {
    .btn-icon-wrapper {
      transition: none;
    }
  }
}

.btn-transition-alt {
  &:hover {
    .icon-gradient {
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      background-clip: initial;
      text-fill-color: initial;
      background: none !important;
      color: $white;
    }
  }
}

// Square Buttons
.btn-square {
  @include border-radius(0 !important);
}

// Wide buttons
.btn {
  &.btn-wide {
    @include button-size($btn-padding-y, ($btn-padding-x * 2), $font-size-xs, $btn-line-height, $btn-border-radius);
  }

  &.btn-wider {
    @include button-size($btn-padding-y, ($btn-padding-x * 3), $font-size-xs, $btn-line-height, $btn-border-radius);
  }
}

.btn-lg {
  &.btn-wide {
    @include button-size($btn-padding-y-lg, ($btn-padding-x-lg * 2), $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }

  &.btn-wider {
    @include button-size($btn-padding-y-lg, ($btn-padding-x-lg * 3), $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }
}

.btn-sm {
  &.btn-wide {
    @include button-size($btn-padding-y-sm, ($btn-padding-x-sm * 2), $font-size-xs, $btn-line-height-sm, $btn-border-radius-sm);
  }

  &.btn-wider {
    @include button-size($btn-padding-y-sm, ($btn-padding-x-sm * 3), $font-size-xs, $btn-line-height-sm, $btn-border-radius-sm);
  }
}

// Btn min-width
.btn {
  &.btn-min-width {
    min-width: 150px;
  }
}

.btn-lg {
  &.btn-min-width {
    min-width: 175px;
  }
}

.btn-sm {
  &.btn-min-width {
    min-width: 135px;
  }
}

// Btn smaller-text
.btn {
  &.btn-smaller-text {
    font-size: 1.05rem;
  }
}
.btn-lg {
  &.btn-smaller-text {
    font-size: 1rem;
  }
}
.btn-sm {
  &.btn-smaller-text {
    font-size: 0.9rem;
  }
}

// Dropdown toggle
.dropdown-toggle {
  &::after {
    position: relative;
    top: 2px;
    opacity: 0.8;
  }
}

.dropright {
  .dropdown-toggle {
    &::after {
      top: 0;
    }
  }
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, 0.1) solid 1px;
}

// Buttons Gradients
$btn-gradients: ();

$gradient-primary: $brand;
$gradient-secondary: $gray-600;
$gradient-success: $green;
$gradient-info: $cyan;
$gradient-warning: $yellow;
$gradient-danger: $red;
$gradient-light: $blue-light;
$gradient-dark: $gray-800;
$gradient-focus: $indigo;
$gradient-alt: $purple;

$gradinet-percent: 15%;

$btn-gradients: map_merge(
  (
    'primary': (
      darken($gradient-primary, $gradinet-percent),
      // color-start
      $gradient-primary,
      // color-end
      0%,
      // gr-start
    ),
    'secondary': (
      darken($gradient-secondary, $gradinet-percent),
      // color-start
      $gradient-secondary,
      // color-end
      0%,
      // gr-start
    ),
    'success': (
      darken($gradient-success, $gradinet-percent),
      // color-start
      $gradient-success,
      // color-end
      0%,
      // gr-start
    ),
    'info': (
      darken($gradient-info, $gradinet-percent),
      // color-start
      $gradient-info,
      // color-end
      0%,
      // gr-start
    ),
    'warning': (
      darken($gradient-warning, $gradinet-percent),
      // color-start
      $gradient-warning,
      // color-end
      0%,
      // gr-start
    ),
    'danger': (
      darken($gradient-danger, $gradinet-percent),
      // color-start
      $gradient-danger,
      // color-end
      0%,
      // gr-start
    ),
    'focus': (
      darken($gradient-focus, $gradinet-percent),
      // color-start
      $gradient-focus,
      // color-end
      0%,
      // gr-start
    ),
    'alternate': (
      darken($gradient-alt, $gradinet-percent),
      // color-start
      $gradient-alt,
      // color-end
      0%,
      // gr-start
    ),
    'light': (
      darken($gradient-light, $gradinet-percent),
      // color-start
      $gradient-light,
      // color-end
      0%,
      // gr-start
    ),
    'dark': (
      darken($gradient-dark, $gradinet-percent),
      // color-start
      $gradient-dark,
      // color-end
      0%,
      // gr-start
    ),
  ),
  $btn-gradients
);

@mixin btn-grad-variant($color-start, $color-end, $gr-start) {
  background-image: linear-gradient(140deg, $color-start -30%, $color-end 90%);
  background-color: $color-start;
  border-color: $color-start;
  color: color-yiq(darken($color-start, 10%));

  &.active,
  &:active,
  &:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, darken($color-start, 3%) 0%, darken($color-end, 3%) 100%);
    color: color-yiq(darken($color-start, 10%));
    border-color: darken($color-start, 10%);
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    color: color-yiq(darken($color-start, 10%)) !important;
    border-color: darken($color-end, 20%) !important;
  }
}

@each $grad, $colors in $btn-gradients {
  $color: nth($colors, 1);
  $color1: nth($colors, 2);
  $color2: nth($colors, 3);

  .btn-gradient-#{$grad} {
    @include btn-grad-variant($color, $color1, $color2);

    &.btn-shadow {
      box-shadow: 0 0.125rem 0.625rem rgba($color1, 0.4), 0 0.0625rem 0.125rem rgba($color1, 0.5);

      &:hover {
        box-shadow: 0 0.125rem 0.625rem rgba($color1, 0.5), 0 0.0625rem 0.125rem rgba($color1, 0.6);
      }
    }
  }
}

.btn-shadow {
  &.active {
    box-shadow: 0 0 0 0 transparent !important;
  }
}

// Links
$link-color: $blue;
//$link-hover-color: theme-color('primary');

// Generate Buttons
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);

    &.btn-shadow {
      box-shadow: 0 0.125rem 0.625rem rgba($value, 0.4), 0 0.0625rem 0.125rem rgba($value, 0.5);

      &:hover {
        box-shadow: 0 0.125rem 0.625rem rgba($value, 0.5), 0 0.0625rem 0.125rem rgba($value, 0.6);
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-shadow-#{$color} {
    &:hover {
      box-shadow: 0 0.125rem 0.625rem rgba($value, 0.4), 0 0.0625rem 0.125rem rgba($value, 0.5);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);

    &.btn-shadow {
      box-shadow: 0 0.125rem 0.625rem rgba($value, 0.4), 0 0.0625rem 0.125rem rgba($value, 0.5);

      &.active:hover,
      &.disabled:hover,
      &:active:hover,
      &:disabled:hover {
        box-shadow: 0 0.125rem 0.625rem rgba($value, 0.5), 0 0.0625rem 0.125rem rgba($value, 0.6);
      }

      &:hover {
        box-shadow: (0px 5px 15px 2px rgba($value, 0.19));
      }
    }
  }
}

.btn {
  position: relative;
  @include transition($btn-transition);
}

.btn-light {
  border-color: darken(#f5f5f5, 10%);
}

.btn-outline-light {
  color: darken(#f5f5f5, 40%);
}

// Filter Buttons
.btn-filter {
  width: 100%;
  color: $white;
  background-color: $white;
  border-width: 4px 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-transform: uppercase;

  i {
    pointer-events: none;
  }

  .btn-vertival-text {
    opacity: 1;
    display: block;
    font-size: 20px;
    line-height: 25px;
    font-style: normal;
    font-weight: 900;
  }
  $buttons: (
    'primary': $primary,
    'danger': $danger,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'light': $light,
    'dark': $light,
    'warning': $warning,
  );

  @each $btn, $color in $buttons {
    &.btn-#{$btn} {
      color: $color;

      &.selected {
        border-top-color: $color;
      }

      &:hover,
      &:focus {
        border-top-color: $color;
        border-bottom-color: $white;
        background: $white;
      }

      &:active {
        opacity: 0.9;
      }
    }
  }
}

.btn-show {
  display: inline-block;
}

.btn-hide {
  display: none;
}

// Blank filter-btn
.btn-filter-blank {
  width: 100%;
  color: $primary;
  background-color: $white;
  border-width: 4px 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
}

.btn-filter-textarea {
  width: 100%;
  color: $primary;
  background-color: $white;
  border-width: 4px 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  padding: 1.4rem 0 0.75rem 1.4rem !important;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  p {
    margin: 0;
  }
}

// Href over-ride
a {
  &.text-primary {
    &:hover,
    &:focus {
      color: $blue !important;
    }
  }
}

.href {
  text-decoration: underline !important;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
}

// download
.btn-download {
  color: $blue;
  font-weight: 700;

  svg {
    margin-right: 10px;
  }

  &:hover {
   font-weight: bolder;
    background: rgba(186, 206, 238, 0.3);
    color: $blue;
  }
}
