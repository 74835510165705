// Variables
$spacer-lg                      : 3rem;
$spacer-sm                      : 1.5rem;

$layout-spacer-lg               : 3rem;
$layout-spacer-x                : 1.5rem;
$layout-spacer-x-active         : 1.5rem;
$layout-spacer-left             : 0;

$app-sidebar-width              : 265px;
$app-sidebar-width-collapsed    : 80px;

$logo-height                    : 18px;
$logo-width                     : 205px;

$logo-height-sm                 : 21px;
$logo-width-sm                  : 240px;

$app-header-height              : 60px;