.alerts-table {
  .text-color-urgent,
  .text-color-critical
  {
    text-transform: capitalize;
    color: $danger;
  }
  .text-color-high,
  .text-color-warning
  {
    text-transform: capitalize;
    color: $warning;
  }
  .text-color-low,
  .text-color-informative
  {
    text-transform: capitalize;
    color: $success;
  }
  .text-color-normal,
  .text-color-error
  {
    text-transform: capitalize;
    color: $secondary;
  }

}

.alert-filtering{
  display: flex;
  justify-content: center;

  .btn-filter,.show-all-alerts {
    flex: 1;
    margin-right: 5px;
    min-width: 110px;
    height: 100px;
  }

  .btn-filter{
    &:nth-child(2){
      margin-left: 5px;
    }
  }

  @include media-breakpoint-up(xl) {
    .btn-filter-textarea {
      .font-weight-bold {
        min-width: 130px;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .btn-filter-textarea {
      .font-weight-bold {
        font-size: 15px;
      }
    }
  }

  @include media-breakpoint-down(md) {
      .btn-filter-textarea {
        display: none;

        .font-weight-bold {
          font-size: 1rem;
        }
      }
      .show-all-alerts{
        display: none;
      }
    }
  @include media-breakpoint-only(xs){
      .btn-filter{
        margin-top: 5px;
        flex: 0 0 100% !important;
      }
    }
}

.alerts-command-btns{
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.delete-btn-wrap.delete-alert{
  text-align: right;
  padding-right: 20px;
}

.close-drop-btn{
  float: right;
}
.visible{
  display: block;
}
.not-visible{
  display: none;
}
.alerts-table-top{
  display: flex;
  justify-content: space-between;
  .toggle-all-or-24{
    background-color: $dark-blue;
    width: 52px;
    height: 32px;
    border-radius: 7px;
    color: white !important;
    visibility: hidden;
    cursor: none;
  }
  &.empty-table-top{
    pointer-events: none;
    .toggle-all-or-24{
      pointer-events: visible;
    }
  }
}
@media screen and  (max-width:1350px){
  .alert-filtering{
    display: flex;
    justify-content: space-around;
    .btn-filter,.show-all-alerts {
      flex: 1;
      height: 100px;
      max-width: 1000px;
    }
    .btn-filter-textarea{
        height: 100px;
        width: 170px ;
        display: inline-block;
      p{
        display: inline-block;
        width: 200px;
      }
    }
  }
  .delete-btn-wrap.delete-alert{
    margin-left: auto;
    &:after{
      margin-right: 8px;
    }
  }
}
@media screen and  (max-width:1034px){
  .show-all-alerts{
    display: none;
  }
  .alerts-table-top{
    .toggle-all-or-24{
      visibility: visible;
      cursor: pointer;
    }
  }
}
@media screen and  (max-width:906px){
  .alerts-table-top{
    &:after{
      display: none;
    }
  }
}
@media screen and  (max-width:891px){
  .delete-btn-wrap.delete-alert{
    margin-left: auto;
    &:after{
      width: 0 !important;
    }
  }
  .alerts-command-btns{
    justify-content: flex-end;
  }
}

@media screen and  (max-width:839px){
  .alert-filtering{
    .btn-filter{
      &:nth-of-type(5){
        margin-top: 5px;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .alerts-table-top{
    .delete-btn-wrap.delete-alert{
      max-width: 315px;
    }
    .alerts-command-btns{
      margin-left: auto;
    }
    .table-search-wrapper{
      margin-top: 10px;
    }
  }

}
@media screen and  (max-width:694px){
  .alert-filtering{
    .btn-filter{
      &:nth-of-type(4){
        margin-top: 5px;
      }
    }
  }
}
@media screen and  (max-width:586px){
  .alert-filtering{
    .btn-filter{
      &:nth-of-type(3){
        margin-top: 5px;
      }
    }
  }
}








