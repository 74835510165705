// Security score components custom style

// Outer Modal
.security-score-modal {
  .rodal-mask {
    opacity: 0;
  }
  .rodal-dialog {
    box-shadow: 0 0.8rem 2.5rem rgba(52, 58, 64, 0.3);
    @include media-breakpoint-down(sm) {
      z-index: 1000;
      top: 10%;
      width: 90% !important;
    }
  }

  .modal-header {
    margin-bottom: 0.25rem;
  }
  .modal-title {
    font-size: 20px;
  }
}

// Inner Modal
.security-score-chart-modal {
  padding: 20px 5px 5px;
  .title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 900;
    padding-bottom: 5px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 23px;
    font-weight: 200;
  }

  // Chart Table
  .chart-table {
    max-height: calc(70vh - 50px);
    overflow-y: scroll;

    .score-box {
      background-color: #f2f4f6;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: $primary;
      padding: 15px 40px;
      margin: 10px 5px 0 ;

      &:first-of-type {
        margin: 0 5px 10px;
      }

      .circle {
        border-radius: 50%;
        border: 1px solid #707070;
        background-color: white;
        width: 60px;
        height: 60px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        font-weight: 800;
      }
      .text-block {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 25px;

        .header-text {
          font-weight: 600;
          color: $primary;
          text-align: left;

          &:nth-of-type(1) {
            color: #545aeb;
          }

        }
        p {
          font-weight: 200;
          margin: 0;
        }
      }

      &:nth-of-type(2) {
        .text-block .header-text {
          color: #ff7d4d;
        }
      }

      &:nth-of-type(3) {
        .text-block .header-text {
          color: #FFA222;
        }
      }

      &:nth-of-type(4) {
        .text-block .header-text {
          color: #74E2B4;
        }
      }

      &:nth-of-type(5) {
        .text-block .header-text {
          color: #78C6FF;
        }
      }
      .action {
        display: none;
        opacity: 0;
      }
      @include media-breakpoint-only(xs){
        display: flex;
        padding: 10px 0;
        flex-direction: column;
      }
    }
  }
}
